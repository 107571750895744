@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Raleway&display=swap");

.offer-title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
}

.offer-title-section h2 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #222222;
}

.follow-my-listing-btn {
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ebebeb;
}

.next-prev-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #28a745;
  width: auto !important;
  /* width: 102px !important; */
}

.next-prev-skip-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #ff214f;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ff214f;
  width: auto !important;
  /* width: 102px !important; */
}

.comment-suggestions-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #28a745;
  /* width: 102px !important; */
}

.follow-my-listing-btn img {
  margin-right: 9.33px;
}

.create-spreadsheet-btn {
  color: white;
  background-color: #28a745;
  border: 1px solid #28a745;
  height: 32px;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.offers-cards {
  margin: 30px 0;
}

.offers-stats-card-container {
  padding: 0 5px;
}

.offers-stats-card {
  background-color: white;
  border: 1px solid #ebebeb;
  padding: 16px 20px;
  border-radius: 4px;
}

.offer-stats {
  display: flex !important;
  flex-direction: row !important;
  justify-items: center !important;
}

.offer-stats h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #222222;
  margin-bottom: 0;
}

.font-15 {
  font-size: 15px !important;
}

.offers-stats-card p {
  color: #727272;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Raleway", sans-serif !important;
  margin-bottom: 6px;
}

.offer-stats p {
  color: #109cf1 !important;
  font-size: 10px !important;
  font-family: "Poppins", sans-serif !important;
  background: rgba(16, 156, 241, 0.1);
  border: 1px solid rgba(16, 156, 241, 0.2);
  border-radius: 2px;
  margin-bottom: 0 !important;
  padding: 4px;
  height: fit-content;
  margin-left: 8px;
}

.hidden {
  display: none;
}

.offers-filter-show-more-btn {
  margin-bottom: 0 !important;
}

.offer-filters-container {
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 20px;
}

.custom-offers-datePicker {
  height: 0;
}

.custom-offers-datePicker > .react-date-picker__wrapper {
  border: 0 !important;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__clear-button {
  display: none;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__calendar-button {
  display: none;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__inputGroup
  > .react-date-picker__inputGroup__divider {
  display: none;
}

.offers-datepicker-btn {
  border: 1px solid #ebebeb;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  height: 32px;
  font-family: "Raleway", sans-serif;
  border-radius: 4px;
  padding-left: 8px;
  cursor: pointer;
  width: 100%;
  padding-right: 8px;
  text-align: left;
  margin-bottom: 24px;
}

.offers-datepicker-btn img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.offer-filter-collapsable-btn {
  font-size: 16px;
  border: 0;
  background: transparent;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.offer-filters-search {
  padding-left: 32px;
  padding-right: 8px;
  height: 32px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #999999;
  width: 100%;
}

.offer-filters-search-container {
  position: relative;
  margin-bottom: 14px;
  margin-top: 12px;
}

.offer-filters-search-container img {
  position: absolute;
  left: 8px;
  top: 8px;
}

.custom-offers-label {
  font-size: 14px;
  color: #222222;
  font-family: "Raleway", sans-serif;
}

.m-vertical-7 {
  margin: 7px 0;
}

.offers-filter-show-more-btn {
  color: #109cf1;
  font-size: 12px;
  font-weight: 600;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
}

.offers-filter-show-more-btn img {
  margin-left: 8px;
  height: 16px;
  width: 16px;
}

.single-offer-filter {
  margin-bottom: 24px;
}

.custom-card {
  box-shadow: none;
  padding: 20px !important;
}

.ml-20 {
  margin-left: 20px;
}

.offer {
  margin-bottom: 0;
}

.homeoffer-details h5 {
  margin: 0;
}

.homeoffer-details ul li p {
  margin-bottom: 0;
}

.homeoffer-details ul li {
  margin: 11px 0;
}

.price-tag {
  margin-bottom: 0 !important;
}

.applied-filters-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-applied-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #109cf1;
  border-radius: 4px;
  background-color: white;
  padding: 3px 5px 3px 12px;
  width: fit-content;
  margin-bottom: 10px;
  margin-right: 10px;
}

.single-applied-filter p {
  font-size: 12px !important;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  margin: 0;
  color: #109cf1;
}

.single-applied-filter img {
  cursor: pointer;
  margin-left: 8px;
}

.new-offers-green-btn {
  height: 40px;
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}

.homeoffer-details ul li p,
.homeoffer-details ul li p span {
  font-size: 14px !important;
}

.new-offers-mobile-status-view {
  display: none;
}

.clear-all-offer-filters-btn {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  width: 100%;
  color: #109cf1;
  font-family: "Raleway", sans-serif;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 14px;
  margin-bottom: 0;
  cursor: pointer;
}

.offer-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offers-filters-modal-btn {
  display: none;
}

.no-results {
  font-size: 14px !important;
}

.noOffers {
margin: 50px 0;
  text-align: center;
  height: 100vh;
}

.DateRangePicker {
  background: white;
  z-index: 1;
  border: 1px solid #b7b6b6;
  position: absolute !important;
  left: 22px;
  top: 50px;
}

.current-offer-stat-cards {
  margin: 16px 0;
}

.current-offer-trends h5 {
  font-size: 17px;
}

.current-offer-trends p {
  padding: 2px 4px;
  font-size: 9px !important;
}

.current-offer-trends-card > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.current-offer-trends {
  margin-bottom: 0 !important;
}

.current-offer-trends-card > div > img {
  width: 12px;
  margin-right: 4px;
}

.current-offer-trends-card > div > p {
  margin-bottom: 0;
}

.other-agent-offers-btn {
  border-radius: 4px;
  margin-bottom: 0 !important;
}

.other-agent-all-offers-table {
  max-height: 580px;
  overflow-y: scroll;
  overscroll-behavior: none;
}

/* .other-agent-all-offers > table > tbody {
  display: block;
  max-height: 580px;
  overflow-y: scroll;
}

.other-agent-all-offers > table > thead {
  display: table;
  width: calc(100%); 
} */

.other-agent-all-offers th {
  position: sticky;
  top: 0;
}

.other-agent-all-offers-table::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.other-agent-all-offers-table::-webkit-scrollbar-track {
  border-radius: 5px;
}

.other-agent-all-offers-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.other-agent-all-offers-table::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.current-offer-trends-card {
  height: 78px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.concent {
  margin-bottom: 0;
  padding-bottom: 16px;
}

.share-offers-modal-container {
  max-height: 160px !important;
  overflow-y: scroll !important;
}

.share-offers-modal-container::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.share-offers-modal-container::-webkit-scrollbar-track {
  border-radius: 5px;
}

.share-offers-modal-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.share-offers-modal-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.ai-icon {
  width: 20px;
}

.homematchx-ai-button {
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  font-size: 12px;
  overflow: hidden;
  font-weight: 600;
  border: 1px solid #a445ff;
}

.homematchx-ai-button:hover {
  background: linear-gradient(90deg, #a445ff, #6b00f5);
  box-shadow: 0 0 20px rgba(164, 69, 255, 0.8), 0 0 30px rgba(107, 0, 245, 0.6);
}

.ai-modal-container {
  padding: 20px 0;
}

.gradient-heading {
  font-size: 2.5rem;
  font-weight: bolder;
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Raleway", sans-serif;
}

.ai-modal-footer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.ai-modal-footer button {
  margin-bottom: 0;
}

.ai-modal-container p {
  font-size: 1rem;
  line-height: 1.5;
  color: #414141; 
  margin: 0 auto 30px;
  margin: 20px auto 10px;
}

.ai-modal-close-btn {
  background: transparent;
  color: black;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 4px;
  margin-right: 12px;
}

.ai-modal-footer .homematchx-ai-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.star-banner {
  position: absolute;
  width: 175px;
  right: 0;
  top: 64px;
}

.weighted-score-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.scoring-percentage-container {
  margin-top: 10px;
  text-align: center;
}

.ai-score-percentage {
  font-size: 14px;
  margin-bottom: 0;
  color: #333;
  font-weight: bold;
}

.weighted-score-toggle label {
  margin: 0;
  color: #414141;
  margin-bottom: 5px;
}
/* .ai-score-card-container {
  background-color: #90ee90;
  border: 4px solid #008000;
  color: white;
  border-radius: 23px;
  font-size: 18px;
  font-weight: bold;
  padding: 4px;
  width: fit-content;
  min-width: 46px;
  height: 46px;
  text-align: center;
  position: absolute;
  right: 0;
} */
.arrow-down-flipped,
.arrow-down-unflipped {
  width: 12px;
  margin-left: 6px;
  transition: transform 0.3s ease-in-out;
}

.arrow-down-flipped {
  transform: rotate(180deg);
}

.scoring-table-container {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  font-family: Arial, sans-serif;
}

.scoring-table-header {
  background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
}

.scoring-table-header th {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  text-align: left;
  padding: 8px;
  font-weight: bold;
  color: #ffffff; /* White text for visibility */
}

.scoring-table-row {
  transition: background-color 0.3s ease;
}

.scoring-table-row-even {
  background-color: rgba(255, 255, 255, 0.05); /* Subtle light row */
}

.scoring-table-row-odd {
  background-color: rgba(255, 255, 255, 0.02); /* Slightly darker alternate row */
}

.scoring-table-row:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Highlight on hover */
}

.scoring-table-category {
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
  padding: 8px;
  color: #ffffff;
}

.scoring-table-score {
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: right;
  padding: 8px;
  color: #ffffff;
}

.scoring-table-total-row {
  background-color: rgba(0, 128, 255, 0.2); /* Slightly bright blue for Total Row */
  font-weight: bold;
}

.scoring-table-total-row td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.view-scoring-details-btn {
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #28a745;
}

.view-scoring-details-btn:hover {
  background-color: transparent;
  color: #28a745;
}

.ai-score-card-container {
  background: linear-gradient(90deg, #6b00f5, #a445ff); 
  color: white;
  box-shadow: 0 0 0 3px rgba(164, 69, 255, 1); 
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 23px;
  font-size: 16px;
  padding: 0 4px;
  font-weight: bold;
  width: fit-content;
  min-width: 46px;
  height: 46px;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
}

.ai-score-card-container .tooltip { 
  bottom: 0 !important;
}

.scoring-tooltip-disclamer {
  margin-top: 10px;
  font-size: 12px;
}

.ai-score-tooltip {
  background-color: #222222;
  color: #fff;
  border: none;
  box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.3)";
  font-size: 14px;
  max-width: 400px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.ai-score-tooltip p, .ai-score-tooltip ul, .ai-score-tooltip ul li, .ai-score-tooltip strong {
  text-align: left;
  color: #979797;
}

.ai-score-tooltip strong {
  font-weight: 500;
}

.ai-score-tooltip .sb-point {
  margin-bottom: 10px;
}

.ai-score-tooltip code {
  font-size: 14px;
}

.tooltip-heading {
  color: white !important;
  font-size: 20px;
  text-align: center !important;
  font-weight: bold;
  margin-bottom: 10px;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip-subheading {
  color: #f8d775 !important;
  margin-bottom: 6px;
}

.ai-score-tooltip .example-calculation {
  background-color: #444;
  padding: 6px;
  border-radius: 4px;
  margin-top: 5px;
  word-wrap: break-word;
  font-family: monospace;
  white-space: pre-line;
  overflow-wrap: break-word;
  text-align: left;
  word-break: break-word
}

.learn-scoring-button {
  background: transparent;
  border: 0;
  outline: none;
  text-align: left;
}

.learn-scoring-button:hover {
  text-decoration: underline;
  background: transparent;
}

.learn-scoring-modal .modal-header {
  border: none;
}

.learn-scoring-modal .modal-body {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
}

.learn-scoring-modal h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.tooltip-subheading {
  color: #c99600 !important;
  font-size: 18px;
  margin-top: 20px;
}

.weighted-list,
.detailed-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.detailed-list ul {
  padding-left: 20px;
}

.example-calculation {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.calc-cancel-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.calc-cancel-btn:hover {
  background-color: #5a6268;
}

@media (max-width: 1235px) {
  .offer-price-container {
    flex-direction: column;
    width: fit-content;
  }
  .new-offers-green-btn {
    margin-top: 16px;
    margin-bottom: 0;
  }
  .current-offer-trends-card {
    height: 110px;
  }
}

@media (max-width: 1200px) {
  .current-offer-trends-card {
    margin-bottom: 12px;
  }
}

@media (max-width: 1160px) {
  .new-offers-mobile-status-view {
    display: block;
  }
  .new-offers-status-view {
    display: none;
  }
}

@media (max-width: 1128px) {
  .offers-stats-card {
    padding: 0 20px 0;
    display: flex;
    height: 85px;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .homeoffer-details {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .house img {
    height: 202px;
    object-fit: contain;
  }
  .offer-title-section {
    flex-direction: column;
  }
  .offer-title-section div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .offer-title-section > div > .follow-my-listing-btn {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .offer-title-section > div > .create-spreadsheet-btn {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .offer-title-section > div > .create-spreadsheet-btn > img {
    margin-right: 10px;
  }
  .offers-stats-card-container {
    padding: 5px !important;
  }
  .offers-filters-modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    color: #109cf1;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #ebebeb;
    height: 40px;
    border-radius: 4px;
    font-family: "Raleway", sans-serif;
    align-self: center;
    width: 100%;
  }
  .offers-filters-modal-btn img {
    margin-right: 8px;
  }
  .offers-desktop-filters {
    display: none;
  }
  .offers-new-mobile-list {
    padding-right: 15px !important;
  }
  .mobile-filters-modal {
    margin: 0;
  }
  .mobile-filters-modal .modal-content {
    border: 0;
    border-radius: 0;
  }
  .mobile-filters-modal .modal-content .modal-body {
    height: 100vh;
    overflow: hidden;
  }
  .mobile-filters-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eff0f0;
  }
  .mobile-filters-modal-header h6 {
    font-size: 22px;
    color: #222222;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    margin-bottom: 0;
  }
  .mobile-filters-modal-header img {
    cursor: pointer;
  }
  .mobile-filters-modal-footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .filter-modal-apply-btn {
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    background-color: #109cf1;
    color: white;
    height: 40px;
    border-radius: 4px;
    border: 0;
    margin-bottom: 0;
    cursor: pointer;
    width: calc(100% - 101px);
  }
  .filter-modal-cancel-btn {
    color: #109cf1;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    height: 40px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #ebebeb;
    margin-bottom: 0;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
  }
  .modal-body-filters .custom-control .custom-offers-label {
    margin: 0;
  }
  .modal-body-filters div .offers-filter-show-more-btn {
    margin-bottom: 0;
  }
  .modal-offer-filters {
    overflow: scroll;
    height: calc(100% - 130px);
  }
  .offers-cards {
    margin: 20px 0;
  }
  .offer-title-section {
    align-items: flex-start;
  }
  .offer-title-section h2 {
    margin-bottom: 20px;
  }
  .offers-new-mobile-list {
    padding: 0 3px 0 3px !important;
  }
  .DateRangePicker {
    background: white;
    z-index: 1;
    border: 1px solid #b7b6b6;
    position: relative !important;
    left: 0;
    top: 0;
  }
  .dashboard-content h3 {
    padding: 0 5px;
  }
  .ai-score-card-container {
    font-size: 14px;
    top: 4px;
  }
}
