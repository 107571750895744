@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

.seller-main-heading {
  font-family: "Raleway", sans-serif;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #222222;
  padding-top: 10px;
}

.dashboard-content {
  background: #f6f7fa;
}

.dashboard-list-single {
  padding: 0;
  box-shadow: none;
}

.sellers-bg-transparent {
  background-color: transparent !important;
}
.seller-list-item-main {
  background: white;
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.verbal-offer-term-check label {
  padding-left: 0 !important;
}

.align-item-center {
  align-items: center;
}

.search-box {
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #999;
  width: 230px;
}

.back-btn {
  padding: 0 30px;
  background-color: transparent;
  border: 1px solid #222222;
  font-size: 18px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-bottom: 8px;
}

.share-btn {
  padding: 0 30px;
  width: 185px;
  background-color: transparent;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: #28a745;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 24px;
}

.share-btn:hover {
  background-color: #28a745;
  color: white;
}

.create-seller-btn {
  padding: 8px 30px;
  width: 185px;
  text-transform: none !important;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.create-seller-btn:hover {
  background-color: white;
  border: 1px solid #28a745;
  color: #28a745;
}

.sellers-name {
  color: #222222;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.p-0 {
  padding: 0;
}

.pin-icon {
  color: #999999;
  font-size: 13px;
}

.address {
  color: #222222;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.draft-p {
  margin-bottom: 10px;
}

.draft-badge {
  background: #0376fc;
  font-size: 12px;
  padding: 3px 8px;
}

.capitalize {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 28px;
  color: #222222;
}

.details-col {
  font-size: 16px !important;
  color: #222222;
}

td.def {
  padding-right: 70px !important;
}

.see-more {
  width: 130px;
  text-transform: none !important;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: white;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  margin-top: 10px;
}

.custom-arrow {
  font-size: 12px;
  padding-right: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.action {
  padding-left: 0;
  margin-bottom: 0 !important;
}

.custom-fa-icon {
  height: 15px;
  width: 15px;
  background: #999999;
  text-align: center;
  border-radius: 16px;
  color: white;
  font-size: 10px;
  padding-top: 3px;
  margin-right: 10px !important;
}

.details-txt {
  font-size: 14px !important;
  color: #222222 !important;
  font-weight: 500 !important;
  padding-right: 20px;
}

.profile-notify {
  font-size: 14px !important;
  margin-top: 80px;
  margin-bottom: 0;
  color: #999999;
  font-weight: 500;
}

.price-tag {
  color: #eb3e54 !important;
  font-weight: bold !important;
  font-size: 22px !important;
  margin-bottom: 30px;
}

.custom-card {
  border: 0;
  padding: 30px;
  margin-bottom: 30px;
}

.best-match-offers-card {
  border: 3px solid #f8c007;
  /* padding: 30px; */
  margin-bottom: 30px;
}

.offers-thumbnail-view-padding {
  padding: 10px 30px 30px 30px;
}

.ml--1 {
  margin-left: -1px;
}

.transparent-btn {
  padding: 0 9px;
  text-transform: none !important;
  background-color: white;
  font-size: 16px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  border: 1px solid #dcdcdd;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 10px !important;
}

.pl-30 {
  padding-left: 30px;
}

.borderless-btn {
  text-transform: none !important;
  background-color: transparent;
  font-size: 16px !important;
  color: #222222;
  height: 44px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 20px;
  border: 0;
  color: #28a745;
}

.pr-10 {
  padding-right: 10px;
}

.underline {
  text-decoration: underline;
  color: #222222;
  margin-right: 10px;
}

.maps-modal-body {
  padding: 1rem;
  margin: 0;
}

.sellers-status-filter {
  border-radius: 5px;
  background: transparent;
  margin-right: 8px !important;
  margin-bottom: 8px;
  border: 1px solid black;
  cursor: pointer;
  padding: 10px 0 !important;
  padding-left: 5px !important;
  min-height: 40px !important;
  margin-top: 1px;
}

.margin-top-none {
  margin-top: 0 !important;
}

.status-change-btn {
  text-decoration: none !important;
  color: #222222 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 0;
  margin-bottom: 4px;
}

.status-change-btn:hover {
  color: #222222 !important;
}

.change-status-icon {
  margin-right: 6px;
  background-color: #999999;
  color: white;
  font-size: 10px;
  border-radius: 16px;
  padding: 3px 2.5px 2px 2.4px;
}

@media (max-width: 1210px) {
  .mobile-responsive-share-btn {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 480px) {
  .mobile-responsive-share-btn {
    margin-bottom: 20px !important;
  }
  .back-btn {
    margin-right: 0;
  }
  .share-btn {
    margin-right: 0;
  }
  .centerly {
    text-align: center !important;
  }
  .mobile-responsive {
    width: 100%;
  }
  .manage-table .action {
    text-align: center;
  }
  .mobile-btn-view-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .sellers-status-filter {
    margin-bottom: 0;
  }
  .mobile-responsive-back-btn {
    margin-bottom: 22px !important;
  }
  .seller-filters-mob-view {
    align-items: flex-start !important;
  }
  .mob-no-label {
    display: none;
  }
  .search-box {
    width: 100%;
  }
  .update-sellers-mob-view {
    margin-left: 0 !important;
  }
  .mob-status-filter {
    margin-top: 20px;
  }
}

@media (max-width: 415px) {
  .mobile-responsive-share-btn {
    margin-top: 19px !important;
    margin-left: 8px;
  }
  .mobile-responsive-back-btn {
    margin-bottom: 0 !important ;
  }
}
