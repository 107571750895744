@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.property-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.main-col {
  padding-top: 30px;
  width: 70%;
}

.side-col {
  padding-top: 30px;
  width: 28%;
}

.property-container {
  width: 100vw !important;
  padding: 0 59px;
  display: flex;
  justify-content: center;
  background: #f6f7fa;
}

.property-main {
  max-width: 1400px;
  /* width: 1400px; */
  min-width: 60%;
}

.image-gallery-image {
  height: calc(100vh - 80px) !important;
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}

.share-home-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer !important;
  margin-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.share-home-btn:hover {
  background-color: transparent;
  text-decoration: underline;
  border: 0;
}

.share-home-btn:focus,
.share-home-btn:focus-visible,
.share-home-btn:active {
  background-color: transparent !important;
  text-decoration: underline !important;
}

.share-home-btn img {
  height: 19px;
  margin-right: 8px;
}

.view-trend-offers-btn {
  background: transparent;
  border: 0;
  color: white;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.view-trend-offers-btn img:not(.global-chart-trend-img) {
  height: 16px !important;
  margin-top: -8px;
  margin-right: 8px;
}

.view-trend-offers-btn:focus,
.view-trend-offers-btn:focus-visible,
.view-trend-offers-btn:active,
.view-trend-offers-btn:hover {
  text-decoration: underline !important;
}

.retailor-container {
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.realtorInfo-modal-body {
  margin: 20px !important;
}

.property-card-padding {
  padding: 40px 30px 24px;
}

.represented-by-realor {
  padding: 30px 20px;
  border: 1px solid #eee;
  background-color: white;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.image-gallery-slide .image-gallery-image {
  width: auto !important;
}

.image-gallery-slide {
  position: absolute;
  top: auto !important;
  width: auto !important;
  margin: 0 !important;
  background: white !important;
}

.trend-modal-heading {
  font-size: 24px;
  font-weight: 600;
  color: black;
}

.share-link-icon {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}

.trend-modal-heading img {
  margin-left: 8px;
  margin-top: -6px;
}

.trend-modal-subheading {
  color: black;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 24px;
}

.light-bold-td {
  font-size: 18px !important;
  font-weight: 500;
  color: #222222;
  text-align: right;
}

.light-weight {
  font-weight: 400 !important;
  text-transform: capitalize;
}

.m-vertical-32 {
  margin: 32px 0;
}

.progress-bar {
  background-color: #1c95d9 !important;
}

.progress {
  border-radius: 30px;
  width: 102%;
  margin-left: -10px;
}

.progress-value {
  font-weight: 500;
  font-size: 18px !important;
  margin: 0;
  color: #222222;
}

.row-spacebtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verified-offers-text {
  color: #222222;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
  margin-bottom: 5px;
}

/* .lissting-price-container {
  padding-top: 0 !important;
} */

.offer-trend-status {
  color: #222222;
  font-size: 16px !important;
  margin: 5px 0 0;
}

.offer-trend-status span {
  font-weight: bold;
}

@media (max-width: 1100px) {
  .property-row {
    flex-direction: column;
  }

  .main-col {
    width: 100%;
  }

  .side-col {
    margin-top: 30px;
    width: 100%;
  }
}

.property-info-box {
  margin-bottom: 10;
  background-color: #2a2a2a;
  padding: 45px 20px 20px 20px;
  font-size: 14;
  min-height: 86%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: white;
}

.details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.details-item {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;
}

@media (max-width: 880px) {
  .details {
    grid-template-columns: 1fr;
  }
}

.amenities-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}

.amenities-icon {
  margin-right: 20px;
}

.amenity {
  font-weight: bold;
}

.overview-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 20px;
}

.overview-item {
  display: flex;
  flex-direction: row;
}

.overview-icon {
  background-color: #f7f7f7;
  padding: 10px;
  color: #999999;
  margin-right: 10px;
  width: 54px;
}

.livin-area {
  padding: 5px;
  width: 54px;
}

.dining-areas {
  height: 54px;
}

.overview-item-info {
  display: flex;
  flex-direction: column;
}

.btn--link {
  width: auto;
  border-radius: 4px;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  opacity: 1;
  transition: opacity ease 0.3s;
}

.btn--link:hover {
  opacity: 0.7;
}

.btn[disabled] {
  opacity: 0.7;
  cursor: unset;
  pointer-events: none;
}

.btn--link-block {
  border: white 2px;
}

.card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: none;
}

.card-header {
  border: none;
  background-color: inherit;
  font-weight: bold;
  padding: 0;
  padding-bottom: 30px;
  font-size: 20px;
}

@media (max-width: 768px) {
  .property-container {
    padding: 0 20px;
    width: 100vw !important;
  }

  .overview-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .overview-card {
    grid-template-columns: 1fr;
  }

  .amenities-card {
    grid-template-columns: 1fr;
  }
}

.blog-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.tooltip-inner {
  max-width: 300px !important;
  padding: 12px 6px !important;
}

.uploadArea{
  background:#f4f4f4;
  border-radius:6px;
  padding:10px;
  margin: 5px;
}
.uploadArea:hover {
  background:#eaeaea;
  cursor:pointer;
}

.switch {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 16px !important;
}

.switch input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  transition: 0.4s !important;
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 18px !important;
  width: 18px !important;
  left: 4px !important;
  bottom: -1px !important;
  background-color: white !important;
  transition: 0.4s !important;
  border: 1px solid #53a451;
}

.switch input:checked + .slider {
  background-color: #53a451 !important;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #53a451 !important;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(18px) !important;
  transform: translateX(18px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}

.volume-controls-container {
  min-width: 200px;
  max-width: 100%;
}

a:hover {
  text-decoration: none;
}

.offersMainHeading {
  font-weight: bold;
  font-size: 22px;
  padding: 30px 0;
  color: #222222;
  font-family: "Raleway", sans-serif;
}

.makeOffer-btnGroup-active {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  background: #53a451;
  color: white;
  transition: 0.5s;
  border: none;
  outline: none;
  height: 44px;
  border-radius: 4px;
}

.makeOffer-btnGroup-inactive {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  color: grey;
  transition: 0.5s;
  border: none;
  outline: none;
  height: 44px;
}

p {
  font-family: "Raleway", sans-serif !important;
}

.block-p {
  background: #f6f7fa;
  padding: 33px 90px 27px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.block-p p {
  margin: 0;
}

.label-font {
  font-size: 16px !important;
  color: #222222 !important;
}

select.form-control:not([size]):not([multiple]) {
  border-radius: 2px;
  border-color: #cccccc !important;
  height: 45px;
  font-size: 16px !important;
  color: #999999 !important;
  padding: 5px 17px !important;
}

.custom-font {
  padding: 9px 15px;
  font-size: 16px;
  color: #999999;
  height: 44px;
}

.pt-14 {
  padding-top: 14px;
}

.radio-btn {
  display: inline;
  margin-right: 36px;
}

.radio-btn Label span {
  color: #999999;
  margin-bottom: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.radio-btn Label input:checked ~ * {
  color: #222222;
}

.input-area {
  height: 88px !important;
  border-radius: 5px;
}

.agent {
  padding: 54px 0 8px;
}

.download {
  margin-bottom: 0;
}

.download img {
  width: 10px;
  cursor: pointer;
}

.letter Label {
  margin-right: 68px !important;
}

.make-offer-heading {
  font-size: 18px;
  font-weight: 600;
  margin-top: 30px;
}

.download-details img {
  margin-right: 3px;
  margin-bottom: 2px;
}

.download Label,
.download-details Label {
  font-size: 14px;
  text-decoration: underline;
  color: #222222;
  margin-right: 15px;
  min-width: 250px;
}

.download Button {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}

.video-icon {
  width: 18px;
  height: 12px;
  margin-right: 11px;
}

.mic-icon {
  width: 14px;
  height: 19px;
  margin-right: 13px;
}

.button-with-icon {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.delete-file-btn {
  border: 1px solid #d94b57;
  color: #d94b57;
  margin-top: 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
  /* margin-top: 23px; */
}

.video-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
}

.custom-form-group {
  height: 44px !important;
  margin-bottom: 0 !important;
}

.video-recorder-modal-body {
  margin: 0 !important;
}

.video-recorder-container {
  padding: 0 !important;
}

.video-recorder-frame {
  height: 435px;
  width: 100%;
}
/* 
.video-recorder-frame-container video {
  height: 435px !important;
  width: 100% !important;
} */

.preview-video-frame {
  width: 490px;
  max-width: 100%;
  height: 223px;
}

.record-Video-heading {
  color: #222222;
  font-size: 22px;
  font-weight: 700;
  font-family: "Raleway";
  margin-top: -22px;
  margin-left: 16px;
  margin-bottom: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.range-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.range-slider-container img {
  margin-right: 7px;
  width: 15.67px;
  height: 20.33px;
}

.ml-30 {
  margin-left: 30px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.record-video-action-container {
  padding: 14px 20px 20px;
}

._1lB9c {
  display: none !important;
}

._2fG9h {
  padding: 0 !important;
}

._1ceqH ._3bC73 {
  margin-bottom: 75px;
}

._37kfa {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
}

._37kfa:hover {
  background: white !important;
  color: #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  border: 1px solid #53a451 !important;
}

._2gd2_ {
  border-radius: 8px !important;
  border: 1px solid white !important;
  padding: 8px 22px !important;
  color: black !important;
}

._2gd2_:hover {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  color: white !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  /* cursor: ew-resize; */
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -ms-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-webkit-slider-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-ms-thumb:hover {
  cursor: pointer;
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
  height: 7px;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

.restart-button {
  color: #53a451;
  border: 1px solid #53a451;
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 0;
}

.finish-recording-btn {
  border: 1px solid #53a451;
  color: white;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.finish-recording-btn img {
  width: 17.59px;
  height: 13.41px;
  margin-right: 10px;
}

.play-pause-btn {
  border: 2px solid #ffd4dd;
  background-color: #fff4f6;
  cursor: pointer;
  margin: 0;
  height: 51px;
  width: 51px;
  border-radius: 50%;
}

.custom-form-group-upload {
  margin-bottom: 0 !important;
}

.play-pause-btn img {
  width: 20px;
  height: 23px;
}

.video-upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-bottom: 0;
  margin-top: 3px;
}

.offer-video-img {
  width: 23px;
  margin-left: 8px;
}

.offer-audio-img {
  width: 30px;
  margin-left: 8px;
}

.flex-start-align-center {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;
}

.custom-form-group {
  height: 44px !important;
  margin-bottom: 0 !important;
}

.video-recorder-modal-body {
  margin: 0 !important;
}

.video-recorder-container {
  padding: 0 !important;
}

.video-recorder-frame {
  height: 435px;
  width: 100%;
}
/* 
.video-recorder-frame-container video {
  height: 435px !important;
  width: 100% !important;
} */

.preview-video-frame {
  width: 490px;
  max-width: 100%;
  height: 223px;
}

.record-Video-heading {
  color: #222222;
  font-size: 22px;
  font-weight: 700;
  font-family: "Raleway";
  margin-top: -22px;
  margin-left: 16px;
  margin-bottom: 18px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.range-slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.range-slider-container img {
  margin-right: 7px;
  width: 15.67px;
  height: 20.33px;
}

.ml-30 {
  margin-left: 30px;
}

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.record-video-action-container {
  padding: 14px 20px 20px;
}

._1lB9c {
  display: none !important;
}

._2fG9h {
  padding: 0 !important;
}

._1ceqH ._3bC73 {
  margin-bottom: 75px;
}

._37kfa {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
}

._37kfa:hover {
  background: white !important;
  color: #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  border: 1px solid #53a451 !important;
}

._2gd2_ {
  border-radius: 8px !important;
  border: 1px solid white !important;
  padding: 8px 22px !important;
  color: black !important;
}

._2gd2_:hover {
  background: #53a451 !important;
  border: 1px solid #53a451 !important;
  border-radius: 8px !important;
  padding: 8px 22px !important;
  color: white !important;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 80px;
  height: 7px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: white;
  /* cursor: ew-resize; */
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
  margin-top: -5px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -moz-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-ms-thumb {
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: white;
  cursor: ew-resize;
  -ms-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
  border: 2px solid #53a451;
}

input[type="range"]::-webkit-slider-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb:hover {
  cursor: pointer;
}

input[type="range"]::-ms-thumb:hover {
  cursor: pointer;
}

/* Input Track */
input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
  border-radius: 5px;
  background-image: linear-gradient(#53a451, #53a451);
  background-repeat: no-repeat;
  height: 7px;
}

input[type="range"]::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

input[type="range"]::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: #53a451;
}

.restart-button {
  color: #53a451;
  border: 1px solid #53a451;
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 24px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 0;
}

.finish-recording-btn {
  border: 1px solid #53a451;
  color: white;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 19px;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: center;
}

.finish-recording-btn img {
  width: 17.59px;
  height: 13.41px;
  margin-right: 10px;
}

.play-pause-btn {
  border: 2px solid #ffd4dd;
  background-color: #fff4f6;
  cursor: pointer;
  margin: 0;
  height: 51px;
  width: 51px;
  border-radius: 50%;
}

.custom-form-group-upload {
  margin-bottom: 0 !important;
}

.play-pause-btn img {
  width: 20px;
  height: 23px;
}

.upload-btn {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 25px;
  cursor: pointer;
  margin-top: 3px;
}

.view-email-btn {
  background-color: transparent;
  border: none;
  color: #4eaddd;
  font-size: 12px;
}

.reply-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 5px 10px;
}

.center-btn {
  background: transparent;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: none;
  border-right: none;
  font-size: 12px;
  padding: 5px 10px;
}

.compose-email-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 20px;
}

.forward-btn {
  background: transparent;
  border: 1px solid lightgray;
  font-size: 12px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 5px 10px;
}

.email-from {
  font-size: 14px;
  font-weight: bold;
}

.email-to {
  font-size: 14px;
}

.email-container {
  border-left: 1px solid rgb(215, 222, 226);
  padding-left: 30px;
}

.email-divider-btn {
  background-color: rgb(129, 210, 255);
  border: none;
  width: 22px;
  height: 22px;
  font-size: 8px;
  border-radius: 50%;
  color: white;
  position: absolute;
  left: 22px;
  text-align: center;
}

.email-divider-icon {
  width: auto !important;
  height: auto !important;
}

.email-caret-right {
  width: 20px;
  height: 20px;
}

.dashboard-content .tab-content {
  width: 100% !important;
}

.offer-package > .nav-tabs .nav-link {
  border: 1px solid transparent !important;
  text-align: center !important;
  width: 120px !important;
}

.offer-package .nav {
  margin: 0px !important;
  background-color: white !important;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  min-height: 300px !important;
  flex: 1 1;
  overflow-y: auto;
  width: 100%;
}

.email-replies-container {
  border: 1px solid rgb(215, 222, 226);
  background: rgb(252, 253, 253);
  padding: 10px;
  border-radius: 5px;
  color: #4eaddd;
}

.email-replies-view-container {
  border: 1px solid rgb(215, 222, 226);
  background: rgb(252, 253, 253);
  padding: 10px;
  border-radius: 5px;
}

.email-replies-text {
  color: #4eaddd;
  font-size: 14px;
}

.email-replies-content {
  color: #222222;
  font-size: 14px;
}
.truncated-text {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis (...) when text overflows */
}

.email-subject-input {
  width: 90% !important;
  border: none !important;
}

.email-subject-label {
  font-size: 14px !important;
}

.add-attachment-label {
  font-size: 14px !important;
}

.pointer {
  cursor: pointer !important;
}

.attachment-item {
  background-color: rgb(232, 236, 240);
  border: 1px solid rgb(232, 236, 240);
  border-radius: 13px;
  padding: 6px 10px;
  font-size: 12px;
}

.attachment-name {
  color: #4eaddd;
}

.compose-emai-section {
  border: 1px solid rgb(215, 222, 226);
  /* border: 1px solid black; */
  border-radius: 10px !important;
}

.email-tooltip {
  background-color: #fff !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2) !important;
}

.email-tooltip > .tooltip-inner {
  background-color: #fff !important;
  font-size: 12px !important;
  max-width: 1000px !important;
  width: 250px !important;
  text-align: left !important;
}

.bs-tooltip-bottom > .arrow {
  display: none;
}

.font-12 {
  font-size: 12px !important;
}

.overflow-scroll {
  overflow-x: scroll !important;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.upload-btn-active {
  border: 1px solid #53a451;
  color: #fff;
  background-color: #53a451;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 25px;
  cursor: pointer;
  margin-top: 3px;
}

.start-recording-btn {
  background-color: #fff4f6;
  border: 1px solid #ffd9e1;
  border-radius: 30px;
  font-size: 16px;
  margin-bottom: 0;
  cursor: pointer;
  color: #ff214f;
  font-weight: 500;
  font-family: "Raleway";
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.start-recording-btn img {
  height: 28px;
  width: 28px;
  margin-right: 16px;
}

.upload-icon {
  margin-right: 13px;
  width: 14px;
  height: 17px;
}

.video-upload-btn-wrapper input[type="file"],
.upload-btn-wrapper input[type="file"] {
  display: none;
}

.submit-btns {
  margin-top: 32px;
  margin-bottom: 54px;
}

.submit-offer {
  cursor: pointer;
  width: 276px;
  padding: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: 0.5s;
  border-radius: 5px;
  border: none;
  outline: none;
  border: 1px solid #53a451;
  background-color: #53a451;
  margin-right: 20px;
  margin-bottom: 15px;
  height: 44px;
}

.submit-offer-seller-text {
  position: absolute;
  width: 200px;
  background-color: #fef;
  left: 39px;
  bottom: 60px;
  border: 1px solid red;
  padding: 10px;
  border-radius: 5px;
}
.submit-offer-seller-image {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  width: 50px;
  position: absolute;
  bottom: 55px;
  left: 0px;
  rotate: -15deg;
}

.submit-offer:disabled {
  border: 1px solid #81b280;
  background-color: #81b280;
}

.submit-offer-seller {
  border: 1px dotted red !important;
  font-size: 12px !important;
  cursor: default;
}

.submit-offer:hover {
  background-color: white;
  color: #53a451;
  border: 1px solid #53a451;
}

.preview-mail-btn {
  cursor: pointer;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  border: 1px solid #222222;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 276px;
  height: 44px;
}

.preview-mail-btn:hover .preview-link {
  color: white;
}

.preview-mail-btn:hover {
  color: white;
  background-color: #222222;
}

.preview-link:hover {
  color: white;
}

.cancel-request {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  color: #d94b57;
  border: 1px solid #d94b57;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 276px;
  height: 44px;
  margin-right: 20px;
  margin-bottom: 15px;
}
.cancel-request:hover {
  color: white;
  background-color: #d94b57;
}

.underline {
  text-decoration: underline;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item span {
  color: #666;
  font-weight: normal;
}
/* 
.radio-item span:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #cccccc;
  background-color: transparent;
}

.radio-item Input[type="radio"]:checked + span:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #017c42;
}
 */

input[type="radio"]:not(.thank) {
  visibility: hidden;
}

input[type="radio"]:not(.thank):after {
  width: 25px;
  height: 25px;
  top: -8px;
  left: -8px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  background-image: url(/static/media/offers-radio-unchecked.022d49dd.svg);
}

input[type="radio"]:not(.thank):checked:after {
  width: 30px;
  height: 30px;
  top: -10px;
  left: -10px;
  position: relative;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
  background-image: url(/static/media/offers-radio-checked.792c3728.svg);
}

.thank {
  height: 8px !important;
}

.nav-tabs {
  border: 0;
}

.padd-lft {
  padding-left: 15px;
}

.notification-bell {
  border-radius: 0 !important;
  width: 24px !important;
  height: 29px !important;
  margin-top: 29px !important;
}

.notification-list ul li {
  margin-left: 0;
  border-bottom: 1px solid #efe7e7;
  width: 100%;
}

.notification-list ul li p {
  margin: 0;
  font-size: 10px !important;
  text-align: right;
  padding-right: 16px;
  color: #9a9a9a;
  cursor: pointer;
  margin-top: -10px;
}

.notification-list ul li:hover {
  background-color: #f3f4f7;
}

.empty-notification ul li p {
  font-size: 16px !important;
  margin-top: 0 !important;
  min-height: 50px;
  padding-top: 10px;
  text-align: left;
  padding-left: 10px;
}

.empty-notification ul li p:hover {
  background-color: white !important;
}

.dropdown-item {
  font-size: 12px !important;
}

.dropdown-item:focus {
  background-color: white;
}

.main-header::-webkit-scrollbar,
.dropdown-menu::-webkit-scrollbar {
  width: 4px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.notification-badge {
  background-color: #d94b57;
  color: white;
  font-size: 14px !important;
  margin-top: -54px;
  width: 22px;
  height: 22px;
  text-align: center;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  margin-left: 16px;
}

.notification-width {
  left: -265px;
  position: absolute;
  width: 300px;
  overflow: hidden;
  background: white;
  top: 84px;
}

.notification-width a {
  max-width: 300px;
  white-space: normal;
}

.content-box-signupCheck {
  color: #7c7c7c;
  text-align: center;
  margin: 0 !important;
  box-shadow: none !important;
  margin-bottom: 40px !important;
  max-width: inherit !important;
}

.yes-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.content-box-signupCheck p {
  font-size: 16px !important;
  color: grey;
}

.content-box-signupCheck a {
  font-weight: 600;
  font-size: 12px !important;
}

.no-offer-found {
  text-align: center;
  margin: 100px 0;
  font-weight: 500;
}

.single-checkbox label {
  padding-left: 6px;
  margin: 5px 0 !important;
  cursor: pointer;
}

.single-checkbox input[type="checkbox"]:checked + label:before {
  display: none;
}

.mb-40 {
  margin-bottom: 40px;
}

.drag-and-drop-container {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px;
  padding: 10px;
  background: #ebecf0;
  border-radius: 4px;
}

.follow-my-listing-icon {
  max-width: 30px;
}

.label-sms-blast {
  text-align: left;
}

.label-sms-blast label {
  margin: 5px !important;
}

.gnerate-file {
  width: 156px;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px !important;
  border: 0;
  cursor: pointer;
  height: 31px;
  background: green;
  margin-right: 15px;
}

[data-rbd-draggable-id] {
  left: auto !important;
  top: auto !important;
}

.concent {
  padding: 20px 0 8px;
}

.comment-container {
  flex-direction: row !important;
}

.date-picker-partioned-form {
  width: 100%;
}

.content-box-preview-offer {
  box-shadow: none;
  margin: 20px 0;
}

.preview-offer-score-tooggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.preview-offer-score-tooggle label {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.powered-by-offerAIde {
  color: #ff214f;
  font-weight: bold;
}

.total-scr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
}

.real-time-scr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  padding-top: 8px;
}

.powered-by-offeraide-sm-txt {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  color: #6c757d;
}

.preview-offer-score-modal-head h5 {
  font-size: 18px;
  font-weight: bold;
}

.gotit-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #28a745;
  border: 2px solid #28a745;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  width: 100px;
  height: 36px;
}

.gotit-btn:hover {
  color: white;
  background-color: #28a745;
}

.submit-anyway-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  border: 2px solid #666666;
  transition: 0.5s;
  border-radius: 5px;
  outline: none;
  background-color: white;
  height: 36px;
  padding: 0 16px;
}

.submit-anyway-btn:hover {
  color: white;
  background-color: #666666;
}

.ai-score-widget-container {
  height: 150px;
  width: 150px;
  position: fixed;
  top: 10px;
  right: 25px;
}

.ai-score-widget-container canvas {
  height: 150px !important;
  width: 150px !important;
  border-radius: 150px;
  background-color: white;
}
.doughnat-score {
  margin: 0 auto;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: 600;
  color: #222222;
  line-height: 38px;
  letter-spacing: 0.01rem;
}

.learn-scoring-button-make-offer {
  margin-bottom: 0;
  border: 0;
  background: transparent;
  color: #8b8b8b;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 1209px) {
  .custom-form-group-upload {
    margin-bottom: 1rem !important;
  }
  .video-upload-btn-wrapper {
    margin-top: 0;
  }
  .makeOffer-btnGroup-active,
  .makeOffer-btnGroup-inactive {
    height: auto;
  }
}

@media (max-width: 450px) {
  .comment-container {
    flex-direction: column !important;
  }

  .comment-btn-otherAgent {
    margin-top: 28px !important;
  }

  .submit-offer-seller-text {
    bottom: 120px !important;
  }

  .submit-offer-seller-image {
    bottom: 115px !important;
  }

  .record-video-action-container {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }
  .play-pause-btn {
    margin-bottom: 20px;
  }
  .button-with-icon,
  .upload-btn {
    min-width: 190px;
  }
  .range-slider-container {
    width: 100%;
    justify-content: center;
  }
  ._1ceqH ._1Yplu {
    text-align: center;
  }
  ._1ceqH ._3bC73 {
    margin-bottom: 0 !important;
  }
  ._1ceqH ._3bC73 ._1YOWG {
    position: relative !important;
    left: 0 !important;
    -webkit-transform: 0 !important;
    transform: 0 !important;
    bottom: 0 !important;
    text-align: center !important;
    display: inline;
  }
  ._qxztz {
    -webkit-transform: translate(-45%) !important;
            transform: translate(-45%) !important;
  }
  .block-p {
    padding: 16px;
  }
  .mobile-pl-0 {
    padding-left: 0;
  }
  .upload-btn-wrapper {
    margin-left: 0 !important;
  }
  .ai-score-widget-container canvas {
    height: 110px !important;
    width: 110px !important;
    border-radius: 110px;
  }
  .ai-score-widget-container {
    height: 110px;
    width: 110px;
    right: 10px;
  }
  .doughnat-score {
    font-size: 25px;
    line-height: 25px;
  }
  .makeOffer-btnGroup-active, .makeOffer-btnGroup-inactive {
    font-size: 15px;
    font-weight: 600;
  }
  .sub-banner {
    height: 200px !important;
}
}

@media (max-width: 1078px) {
  .mt-95 {
    margin-top: 60px;
  }
}

.comment-box {
  height: 44px !important;
  margin-top: 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.dashboard-container {
  max-width: 1000px;
  margin: 30px;
}

.dashboard-main {
  padding: 30px 30px;
  background-color: #f6f7fa;
}

.sections-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #222222;
}

.header-subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;

  transition: opacity ease 0.2s;
  opacity: 1;
}

.link:hover {
  opacity: 0.7;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.header-second-row {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.activity-icon {
  font-size: 20px;
  color: #999999;

  margin-right: 10px;
}

.alert {
  padding: 9px 15px 9px 30px;
  border-radius: 12px;
  text-transform: initial;
}

.alert a,
.alert span {
  font-size: 16px;
}

.alert a {
  margin-left: 5px;
}

.alert .close-btn {
  font-size: 18px;
}

.alert-success {
  background: #28a745;
}

.alert-success span {
  color: #ffffff;
}

.alert-warning {
  background: transparent;
  border: 1px solid #d94b57;
}

.alert-warning span {
  color: #d94b57;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  font-size: 16px;
  width: 100%;
  height: auto;
  margin: 3px auto;
  position: relative;
}

.tabs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.period-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pagination > li.active > a {
  background-color: red;
  color: #fff;
}
.pagination > li > a,
.pagination > li > span {
  border-radius: 3px !important;
}

.pagination > li > a {
  box-shadow: none !important;
}

.period-filter-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #999999;

  margin-right: 12px;
}

.result-per-page-label {
  margin-bottom: 0 !important;
}

.period-filter button {
  background-color: transparent;
  border: none;
}

.period-filter button span {
  border-bottom: 1px #222222 dashed;
  padding-bottom: 3px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-icon {
  margin-left: 10px;
  color: #cccccc;
}

.tab {
  border-bottom: 1px solid #999999;
  transition: border-color ease 0.1s;
}

.tab span {
  font-size: 22px;
  line-height: 22px;
  color: #999999;
  transition: color ease 0.1s;
}

.tab-active {
  border-bottom: 3px solid #222222;
}

.tab-active span {
  font-size: 22px;
  line-height: 22px;
  color: #222222;

  font-weight: bold;
  text-align: center;
}

.tab,
.tab-active {
  padding: 10px 25px;
}

.tab:hover > span {
  color: #666666;
}

.tab:hover {
  border-color: #666666;
}

.tab-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  gap: 30px;
}

.properties-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px;
  border: 0px;
  background-color: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
}

/* DELETE IT */
.card + .card {
  margin-top: 0px !important;
}

.properties-card-icon {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #f6f7fa;
  margin-right: 45px;
  max-width: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties-card-info {
  width: 100%;
  text-align: center;
}

.properties-card-info h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 52px;
  color: #222222;
}

.properties-card-info span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
}

.list-heading {
  margin-top: 50px;
  margin-bottom: 30px;
}

.list-heading h5 {
  margin-bottom: 0px;
}

.list-heading .period-filter-label {
  margin-right: 10px;
}

.list-heading .period-filter .dropdown-toggle {
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
}

.list-heading .period-filter .dropdown-toggle span {
  border: 0px;
}

.list-heading .period-filter .dropdown-icon {
  margin-left: 30px;
}

.table-customized {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}

.width-200 {
  width: 200px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.best-match-tag-table-view {
  background-color: #f8c007;
  color: #af6e00;
  margin-left: -17px;
  margin-top: -57px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
  position: absolute;
}

.my-listings-best-match-tag {
  background-color: #f8c007;
  color: #af6e00;
  margin-left: -41px;
  margin-top: -23px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
  position: absolute;
}

.first-td {
  border-left: 3px solid #f8c007 !important;
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
  min-width: 110px;
}

.td-in-btwn {
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
}

.last-td {
  border-right: 3px solid #f8c007 !important;
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
}

.properties-list-card {
  background-color: #ffffff;
  /* -webkit-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12); */
  padding: 15px 15px;
  margin-bottom: 30px;
}

.best-match-properties-list-card {
  background-color: #ffffff;
  /* -webkit-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12); */
  padding: 0 15px 15px;
  margin-bottom: 30px;
  border: 3px solid #f8c007;
}

.custom-best-match-left-border {
  border-left: 3px solid #f8c007;
  border-top: 3px solid #f8c007;
  border-bottom: 3px solid #f8c007;
  display: inherit;
}

.best-matched-normal-td-border {
  border-top: 3px solid #f8c007;
  border-bottom: 3px solid #f8c007;
}
/* border-left: 3px solid #f8c007;
border-top: 3px solid #f8c007;
border-bottom: 3px solid #f8c007;
display: block;
height: auto;
vertical-align: middle; */

.best-match-tag {
  background-color: #f8c007;
  color: #af6e00;
  margin: 0;
  margin-left: -16px;
  margin-top: -1px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
}

.best-match-tag i {
  color: #af6e00;
  margin-right: 10px;
}

.properties-list-card .sections-title {
  font-size: 20px;
  margin-bottom: 15px;
  max-height: 50px;
  height: 50px;
  overflow: hidden;
}

.properties-list-card .badge {
  margin-bottom: 15px;
}

.badge-success i,
.executed-badge i {
  margin-right: 5px;
}

.accepted-detail {
  margin-bottom: 15px;
}

.accepted-detail ul li {
  margin-bottom: 10px;
}

.accepted-detail p {
  font-size: 13px !important;
  color: #676767;
  margin: 0px 5px 0px 0px;
  min-width: 85px;
}

.accepted-detail a {
  font-size: 14px;
  color: #4d4d4d;
  width: 100%;
}

.accepted-detail .accepted-date {
  color: #222222;
  margin-right: 0;
}

.purchase-price {
  margin-bottom: 30px;
}

.purchase-price h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.purchase-price p {
  font-size: 26px;
  color: #ff214f;
  font-weight: 600;
  margin-bottom: 0px;
}

.house-detail {
  margin-bottom: 0px;
}

.house-detail ul li {
  margin-bottom: 10px;
}

.house-detail ul li:last-child {
  margin-bottom: 0px;
}

.house-detail p {
  width: 100%;
  font-size: 14px;
  color: #4d4d4d;
  margin-bottom: 0px;
}

.house-detail .btn-secondary {
  background-color: #28a745;
  font-size: 16px;
  border-color: #28a745;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.house-detail .link {
  font-size: 16px;
  display: inherit;
  margin: 0 auto;
  text-align: center;
}

.view-more-button {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  background: transparent !important;
}

.view-more-button:hover {
  color: #000;
}

.first-td-width {
  min-width: 45px;
  width: 45px;
  max-width: 45px;
}

.no-shadow {
  box-shadow: none !important;
}

.all-offers .sections-title {
  margin-bottom: 50px;
}

.all-offers .list-heading .period-filter .dropdown-toggle {
  margin-bottom: 10px;
}

.all-offers .period-filter-label {
  margin-bottom: 10px;
}

.slick-slider .slick-track {
  padding-left: 0px !important;
}

.slick-slide {
  padding-top: 30px;
}

.slick-slide .properties-list-card {
  margin-right: 20px;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  padding-left: 0px !important;
}

.slick-next,
.slick-prev {
  width: 0 !important;
  height: 0 !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 40px !important;
  opacity: 0.75 !important;
  /* border-radius: 50%;
  box-shadow: 1px 1px 10px #cccccc !important; */
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0 !important;
  display: none;
}

.slick-next {
  right: 20px !important;
}

.badge-review {
  background-color: #ffc107;
  color: #000;
}

.badge-seller-review {
  background: #fd8d14;
  color: #fff;
}

.see-all-offers {
  margin-bottom: 15px;
}

.see-all-offers .link {
  font-size: 14px;
  color: #4d4d4d;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
}

.select-allbar {
  background-color: #e9ecef;
  padding: 10px 20px;
}

.select-allbar .link {
  font-size: 14px;
  color: #4d4d4d;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  margin-right: 15px;
}

.all-offers .table thead th {
  background-color: #222222;
  color: #ffffff;
  border: 0;
  vertical-align: inherit;
}

.custom-checkbox {
  margin-right: 15px;
}

.all-offers .table .btn-theme {
  background-color: #28a745;
  border-color: #28a745;
  padding: 5px 25px 4px;
  font-size: 14px;
  margin-bottom: 15px;
}

.share-link {
  width: 100%;
  text-align: center;
}

.properties-card-img img {
  max-width: 100%;
  margin-bottom: 10px;
  width: 100%;
  max-height: 176px;
  height: 176px;
}

.dashboard-main .house-detail .link {
  text-decoration: none;
  color: #fff;
}

.pr-0 {
  padding-right: 0 !important;
}

.offer-address-data {
  min-width: 150px;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-title {
  font-size: 30px;
  color: #222222;
  font-weight: 600;
}

.offers-table {
  width: 100%;
}

.modal-header {
  border-bottom: 0;
  padding: 20px 20px 0;
}

.modal-body {
  margin: 0 20px;
  padding: 0;
}

.modal-chart-btn {
  font-size: 14px !important;
  text-decoration: underline;
  color: #222222;
  text-transform: none !important;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  border: 0;
}

.modal-lg {
  max-width: 930px !important;
}

.modal-tr-header {
  background-color: black;
  color: white;
  border-top: 10px solid white;
}

.table-row {
  background-color: #f5f5f5;
  border-top: 10px solid white;
}

.address-td {
  width: 195px;
  padding: 20px 40px;
}

.modal-tr-header th {
  padding-left: 40px;
}

.table-row td {
  padding-left: 40px;
  color: #222222;
  font-weight: 500;
}

.go-to-offer-btn {
  color: #28a745 !important;
  background: transparent;
  padding: 7px 25px;
  border: 1px solid #28a745;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 25px;
}

.go-to-offer-btn:hover {
  color: white !important;
  background: #28a745;
  border: 1px solid #28a745;
}

.mb-30 {
  margin-bottom: 30px;
}

.status-badge {
  padding: 3px 8px;
}

.review-badge {
  padding: 3px 8px;
  background: #ffc107;
  color: #222222;
}

.seller-review-badge {
  padding: 3px 8px;
  background: #fd8d14;
  color: #222222;
}

.review-badge i {
  margin-right: 5px;
}

.seller-review-badge i {
  margin-right: 5px;
}

.not-accepted-badge {
  padding: 3px 8px;
  background: #dc3545;
  color: white;
}

.executed-badge {
  padding: 3px 8px;
  background: #0a8c77;
  color: white;
}

.not-accepted-badge i {
  margin-right: 5px;
}

.offers-modal {
  overflow: hidden;
}

.offers-search-fields {
  padding: 11px 5px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  background: #fff;
  height: auto;
}

.all-offers-table-row {
  border-top: 8px solid #e5e5e5;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.ml-6 {
  margin-left: 6px;
  margin-right: 0;
}
.pr-0 {
  padding-right: 0;
}

.delete-from-list {
  color: #222222 !important;
  text-decoration: underline !important;
  font-size: 16px !important;
  margin-bottom: 8px !important;
  margin-top: 6px !important;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #017c42;
  font-size: 13px;
  font-weight: 100;
}

.highchart-container {
  margin-bottom: 70px;
}

.highcharts-title {
  display: none;
}

.highcharts-credits {
  display: none;
}

.highcharts-axis text {
  text-align: right;
}

.unread-notification {
  color: #eb3e54 !important;
}

.read-notification {
  color: #9a9a9a !important;
}

.unread-dot {
  height: 7px;
  width: 7px;
  background-color: #eb3e54;
  border-radius: 7px;
  margin-top: 7px;
}

.dashboard-notification-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allOffers-share-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: 5px;
}

.share-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.st-has-labels .st-btn.st-remove-label > span {
  display: inline-block !important;
}

.st-has-labels .st-btn.st-remove-label {
  min-width: 116px !important;
}

.st-btn {
  display: inline-block !important;
  margin-bottom: 8px;
}

.st-total {
  display: none !important;
}

.activities-container {
  border-left: 3px solid #b0b0b0;
  background: white;
  padding: 10px 10px;
  border-radius: 2px;
}

.activities-row {
  display: flex;
  flex-direction: row;
}

.margin-vert-6 {
  margin: 16px 0;
}

.activities-time {
  display: flex;
  flex-direction: row;
}

.back-sellers {
  margin-bottom: 0 !important;
}

.activity-time {
  color: #bbbbbb;
  font-size: 12px !important;
  margin: 0;
}

.activities-row p {
  font-size: 16px !important;
  margin: 0;
}

.activities-row i {
  margin-top: 3px;
}

.activities-dashboard {
  min-height: 90vh;
}

.badge-black {
  color: #fff;
  background-color: black;
}

.margin-top-20 {
  margin-top: 20px;
}

.delete-offer {
  background-color: transparent;
  border-radius: 3px;
  color: black;
  font-weight: 500;
  margin-bottom: 5px;
  border: 0;

  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.delete-title {
  font-size: 24px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 30px;
}

.confirm-delete-btn {
  background-color: #28a745;
  border: 0;
  padding: 5px 55px 4px;
  font-size: 14px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.cancel-btn {
  background-color: #dc3545;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}
.cancel-agent-btn {
  background-color: #dc3545;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}
.add-agent-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  padding: 5px 26px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.delete-btn {
  margin: 5px 0 15px;
}

.delete-btn-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.webkit-appearance-auto {
  -webkit-appearance: button-bevel !important;
}

/* Custom CSS */
.react-joyride__tooltip button span {
  color: #fff !important;
  font-size: 12px;
  font-weight: 1000;
  /* padding: 0px; */
}

.react-joyride__tooltip {
  padding-top: 30px !important;
}

.react-joyride__tooltip p {
  font-size: 12px;
}

.react-joyride__tooltip h5 {
  font-size: 22px;
  font-weight: 600;
  color: #222222;
}

.react-joyride__tooltip button {
  height: 20px;
  padding: 0px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  border-radius: 3px;
  padding-top: 8px !important;
  padding-bottom: 18px !important;
  font-size: 12px !important;
  font-weight: 1000;
}

.react-joyride__tooltip button[title="Close"] {
  top: 10px !important;
}
.dashboard-modal-text {
  font-size: 12px !important;
  color: gray;
}
.dashboard-modal-text-left {
  font-size: 12px !important;
  color: gray;
  text-align: left !important;
}
.dashboard-modal-text-red {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #dc3545 !important;
  text-align: left !important;
}

.st-has-labels .st-btn.st-remove-label {
  min-width: 95px !important;
}

@media (max-width: 992px) {
  .modal-lg {
    max-width: 800px !important;
  }
  .table-row td {
    padding-left: 10px;
  }
  .modal-body {
    margin: 0;
    padding: 10px;
  }
  .go-to-offer-btn {
    margin-right: 10px;
  }
  .offers-table {
    min-width: 690px;
    overflow-x: scroll;
    width: 100%;
  }
  .modal-tr-header th {
    padding-left: 10px;
  }
  .my-listings-best-match-tag {
    margin-left: -11px;
  }
}

@media (max-width: 415px) {
  .modal-title {
    font-size: 20px;
  }

  .period-filter-label {
    display: block;
    text-align: center;
  }

  .sellers-status-filter {
    text-indent: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .tab-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .slick-slide .properties-list-card {
    margin-right: 0;
  }

  .accepted-detail p {
    font-size: 13px !important;
    min-width: 85px;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-main {
    padding: 0px 0px;
    background-color: #f6f7fa;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1224px) {
  .tab-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.analytics-main {
  background-color: #f6f7fa;
  width: 100%;
  min-height: 91.1vh;
}

.highcharts-axis text {
  display: none;
}

.highcharts-no-tooltip {
  display: none;
}

.ml-15 {
  margin-left: 15px;
}

.dropdown-menu.show {
  padding: 8px;
}
.drop {
  max-height: 200px;
  overflow-y: scroll;
}
.mob-dropdown-brokerage {
  -webkit-transform: translate3d(0px, 32px, 0px) !important;
          transform: translate3d(0px, 32px, 0px) !important;
}

.propertyFilterToggle img {
  height: 22px;
  width: 22px;
  margin: 0 0 2px 5px;
}

.propertyFilterChkBox {
  margin-right: 4px;
}

.propertyFilterToggle {
  background: transparent;
  border: none;
  margin-bottom: 5px;
  margin-right: 15px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
}

.custom-dropdownMenu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
}

.z-index {
  z-index: 10000 !important;
}

.custom-dropdownMenu-search {
  z-index: 1000;
  position: absolute;
  background-color: white;
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
  height: 315px !important;
  overflow: scroll;
}

.charts-main-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 20px;
  margin-left: 0;
  /* border: 2px solid white; */
}

.average-offers-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  margin-right: 0 !important;
}

.maps-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
}

.charts {
  color: red;
}

.charts-main-container:hover {
  /* border: 2px solid #109cf1; */
  cursor: pointer;
}

.property-engagement-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.chart-title {
  color: #222222;
  font-size: 14px !important;
  font-weight: 600;
}

.font-18-title {
  color: #222222;
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 25px;
}

.chart-title-btn {
  color: #222222;
  font-size: 14px !important;
  font-weight: 400;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-bottom: 1px solid white;
}

.chart-title-btn:hover {
  border-bottom: 1px solid #000000;
}

.property-engagement-tools select {
  font-size: 14px !important;
  border: 0;
  color: #222222;
  width: 140px;
  cursor: pointer;
}

.property-engagement-chart {
  width: 100%;
}

.highcharts-scrolling::-webkit-scrollbar {
  height: 8px;
}
.highcharts-scrolling::-webkit-scrollbar-track {
  background: #efefef;
  border-radius: 2px;
}

.highcharts-scrolling::-webkit-scrollbar-thumb {
  background: #109cf1;
  border-radius: 2px;
}

.chart-mr-left {
  margin: 0 15px;
}

.green-dot {
  margin-top: 8px;
  height: 9px;
  width: 9px;
  border-radius: 9px;
  background-color: #28a745;
}

.red-dot {
  margin-top: 8px;
  height: 9px;
  width: 9px;
  border-radius: 9px;
  background-color: #eb3e54;
}

.analytics-centered-row {
  display: flex;
  flex-direction: row;
}

.offer-days {
  color: #999999;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0;
}

.up-arrow {
  height: 14px;
  margin-right: 5px;
}

.green-percentage-value {
  font-size: 14px !important;
  font-weight: 500;
  color: #28a745;
  margin: 0;
}

.red-percentage-value {
  font-size: 14px !important;
  font-weight: 500;
  color: #eb3e54;
  margin: 0;
}

.ml-8 {
  margin-left: 8px;
}

.gm-bundled-control-on-bottom {
  bottom: 80px !important;
}

.analytics-total-offers {
  margin: 0;
  color: #000000;
  font-size: 18px !important;
  margin-right: 10px;
}

.center-me {
  align-items: center;
}

.flex-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.analytics-btn-group {
  background-color: white;
  border-radius: 5;
  border: 0;
  margin-bottom: 10px;
}

.analytics-btn-group-btns {
  background-color: #e9ecef;
  border-radius: 5;
  border: 0;
  color: #222222;
  font-size: 14px !important;
  margin-top: 0;
  height: 25px;
  padding: 0 19px;
}

.analytics-agent-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 20px;
  height: 180px;
  margin: 0 15px 30px;
  border: 2px solid white;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.analytics-agent-container:hover {
  border: 2px solid #109cf1;
  cursor: pointer;
}

.agent-tag {
  font-size: 10px !important;
  color: #222222;
  margin: 0;
  margin-top: 10px;
}

.agent-name {
  font-family: 16px !important;
  color: #000000;
  font-weight: 500;
  margin: 0;
}

.analytics-agent-img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.donutChart {
  height: 120px;
  width: 226px;
  overflow: hidden;
}

.analytics-row {
  display: flex;
  flex-direction: row;
}

.orange-circle {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  border: 3px solid #ffc107;
  margin-right: 6px;
}

.green-circle {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  border: 3px solid #28a745;
  margin-right: 6px;
}

.blue-circle {
  height: 10px;
  width: 10px;
  border-radius: 8px;
  border: 3px solid #109cf1;
  margin-right: 6px;
}

.price-range-text {
  font-size: 14px !important;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.home-other-accessory {
  font-size: 10px !important;
  font-weight: 500;
  color: #999999;
  margin: 0;
  margin-top: 10px;
}

.donut-chart-heading {
  margin-bottom: 10px;
}

.circle-shaped {
  font-weight: 600;
  background-color: #109cf1;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 100px;
  width: 100px;
  min-width: 100px;
  border-radius: 100px;
}

.circle-shaped-text {
  font-size: 40px !important;
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
}

.price-difference {
  font-size: 14px !important;
  font-weight: 400;
  color: #000000;
}

.mt-10 {
  margin-top: 10px;
}

.donut-chart-value {
  color: #000000;
  font-size: 24px !important;
  font-weight: 600;
  position: absolute;
  left: 25%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.pl-0 {
  padding-left: 0;
}

.mr-15 {
  margin-right: 15px;
}

.map-filters select {
  font-size: 14px !important;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  margin: 4px 0;
  padding: 3px 0;
  width: 100%;
}

.states-select {
  width: 66px;
}

.zip-select {
  width: 46px;
}

.analytics-input-label {
  font-size: 12px !important;
  font-weight: 500;
  border: 0;
  color: #999999;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px 0;
  white-space: nowrap;
}

.analytics-property-address-label {
  font-size: 14px !important;
  border: 0;
  color: #222222;
  cursor: pointer;
  margin: 5px 0;
  /* padding: 5px 0; */
}

.total-offers-by-listings-margin {
  margin: 0 0 30px 0;
}

.average-offers-per-listing-container {
  background-color: #109cf1;
  height: 381px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padding-20 {
  padding: 25px 20px;
}

.analytics-table-sides {
  padding: 20px 5px;
}

.average-offers-text {
  color: white;
  font-size: 14px !important;
  font-weight: 600;
  padding: 0px 15px;
  text-align: center;
}

.average-offers {
  font-size: 100px !important;
  font-weight: 600;
  color: white;
  margin: 0;
}

.analytics-price-input {
  width: 120px;
  padding: 6px 10px;
  color: #999999;
  font-size: 14px !important;
  border: 1px solid #e1dfdf;
  margin: 4px 0;
}

.price-inputs {
  width: 70px;
  padding: 4px 8px;
  color: #999999;
  font-size: 12px !important;
  border: 1px solid #e1dfdf;
}

.analytics-search-input {
  border-radius: 2px;
  padding: 8px 8px;
  color: #999999;
  font-size: 14px !important;
  border: 1px solid #e1dfdf;
  width: 100%;
}

.avg-offers-terms {
  padding: 20px 0 25px;
  margin: 0;
}

.price-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.maps-filters-container {
  margin-top: 60px;
  border-left: 1px solid #d0d0d0;
  padding-left: 20px;
}

.analytics-table-td {
  font-size: 14px !important;
  font-weight: 500;
  height: auto !important;
}

.green-box {
  height: 14px;
  width: 14px;
  background-color: #28a745;
  margin-right: 10px;
}

.blue-box {
  background-color: #129de0;
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.box-label {
  font-size: 12px !important;
  font-weight: 400;
  color: #000000;
  margin: 0;
}

.ml-20 {
  margin-left: 20px;
}

.total-received-offers-container {
  background-color: #109cf1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.total-avg-offers {
  font-size: 100px !important;
  color: white;
  font-weight: 600;
  margin-bottom: 0;
}

.average-terms-container {
  padding: 20px;
  padding-left: 5px;
}

.custom-spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALcAAAABCAYAAACc/T/3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC8SURBVHgB7ZDNDcIgGIZLm3J3A93AblA3aCdQTxyNE1QnMB45qRNUJ7Ab6AaygXd+fUkgMaaHnjyV5MvHwwMBXsI5fyUYjLFZmE9RT3ABdt4RQro8z2sp5buPnXO3NE136I8hjPNnY8wFa/fgj6hrZGvtHq2LjL3bLMsEpm3wa9/hT8HX/t3Ycwh+gVbCN5FxZ4XafPkV/NKz1rrAfxq8oRrClNKJUqoFl32M7Aiy83+do8RPtmLM+i9ZJx8hpge7rACxfwAAAABJRU5ErkJggg==);
  background-position: center bottom;
  background-repeat: repeat-x;
  margin: 10px 0;
}

.analytics-text-normal {
  font-size: 14px !important;
  line-height: 10px;
  color: #000000;
  font-weight: 500;
  background-color: white;
  margin: 0;
}

.margin-avg-head {
  margin-bottom: 60px;
}

.analytics-lined-td {
  font-size: 16px !important;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid black;
  margin: 0;
  display: initial;
  cursor: pointer;
}

.analytics-find-buyer-table td {
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid black;
}

.other-agents-filter {
  font-size: 14px !important;
  background-color: white !important;
  color: #28a745 !important;
  border: 0 !important;
  border-bottom: 1px dashed #28a745 !important;
  padding: 0;
  cursor: pointer;
  line-height: 15px;
  font-weight: 500;
}

.analytics-offer-details-btn {
  background-color: white;
  color: #28a745;
  border: 0;
  border-bottom: 1px solid #28a745;
  font-size: 14px !important;
  padding: 0;
  cursor: pointer;
  line-height: 15px;
}

.analytics-offer-details-btn:hover,
.analytics-offer-details-btn:focus,
.analytics-offer-details-btn:active,
.analytics-offer-details-btn:visited,
.analytics-offer-details-btn:focus-visible,
.analytics-offer-details-btn:focus-within {
  background-color: white;
  color: #28a745;
}

.address-content-width {
  width: 200px;
  padding: 10px 16px !important;
}

.table-normal-text {
  font-size: 16px !important;
  color: #000000;
  font-weight: 500;
  margin: 0;
}
.all-offers {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
.find-buyer-agents-filters {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  border-radius: 4px;
}

.find-buyer-agents-filters select {
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-right: 15px;
  padding: 5px;
}

.width-60 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.analytics-offer-days {
  margin-bottom: 10px;
}

.small-font-text {
  font-size: 12px !important;
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.white-border {
  border-top: 8px solid white !important;
}

.custom-price-options-list {
  width: 180px;
  /* border: 1px solid black; */
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-address-options-list {
  width: 350px;
  position: absolute;
  background: white;
  z-index: 3;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-price-options-list::-webkit-scrollbar {
  width: 4px;
}

.custom-price-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.custom-price-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.custom-users-filter-list {
  width: 200px;
  position: absolute;
  background: white;
  z-index: 3;
  overflow-x: hidden;
  max-height: 410px;
  overflow-y: scroll;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-users-filter-list::-webkit-scrollbar {
  width: 4px;
}

.custom-users-filter-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.custom-users-filter-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.down-arrow {
  margin-left: 10px;
  width: 10px;
}

.font-15 {
  font-size: 15px !important;
}

.price-options-list-p:hover p {
  color: white;
}

.analytics-price-dropdown-btn {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

.analytics-price-dropdown-inner-btn {
  border: 0;
  background: transparent;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 8px;
}

.analytics-price-dropdown-inner-btn p {
  color: #999999;
  font-size: 14px !important;
  margin: 0;
}

.analytics-price-dropdown-inner-btn:hover,
.analytics-price-dropdown-inner-btn:hover p {
  background-color: #1e90ff;
  color: white !important;
}

.analtics-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.padd-6 {
  padding: 6px;
}

.margin-0 {
  margin: 0 !important;
}

.extra-padd-left {
  padding-left: 38px;
}

.analytics-price-input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aeaeae;
  opacity: 1; /* Firefox */
}

.analytics-price-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aeaeae;
  opacity: 1; /* Firefox */
}

.analytics-price-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeaeae;
}

.analytics-price-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aeaeae;
}

.analytics-agent-img {
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.analytics-agent-details {
  margin-top: 30px;
}

.analytics-agent-details a,
.analytics-agent-details p {
  font-size: 14px !important;
  margin: 0;
}

.analytics-agent-details i {
  margin-right: 8px;
}

.agent-about {
  font-size: 20px !important;
  font-weight: 600;
  color: #000000;
  margin-top: 20px !important;
}

.mVertical-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.font-40 {
  font-size: 40px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.phone-icon {
  margin-right: 12px !important;
}

.list-arrow {
  width: 11px;
  margin-left: 6px;
}

.analytics-map-large-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 0;
}

.large-filter-options-list {
  width: 196px;
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.large-filter-options-list::-webkit-scrollbar {
  width: 4px;
}

.large-filter-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.large-filter-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.analytics-map-nrml-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  max-width: 100%;
  width: 185px;
  margin-bottom: 0;
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div > div {
  max-height: 1px !important;
  margin: 0 !important;
  width: 100% !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div:first-of-type {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 62px !important;
  border-radius: 4px !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div
  button:last-of-type {
  background: #ffffff !important;
  border-radius: 0px 0px 4px 4px !important;
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div button img {
  width: 9px !important;
  max-height: 9px !important;
  -webkit-filter: brightness(0) saturate(100%) invert(62%) sepia(25%) saturate(1%)
    hue-rotate(314deg) brightness(94%) contrast(89%);
          filter: brightness(0) saturate(100%) invert(62%) sepia(25%) saturate(1%)
    hue-rotate(314deg) brightness(94%) contrast(89%);
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div button {
  width: 32px !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div:first-of-type {
  width: 32px !important;
}

.analytics-map-rad-filter,
.analytics-map-pri-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 0;
}

.nrml-filter-options-list {
  width: 110px;
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.nrml-filter-options-list::-webkit-scrollbar {
  width: 4px;
}

.nrml-filter-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.nrml-filter-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.flex-row {
  display: flex;
}

.offer-map-location-block {
  justify-content: space-between;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.total-offer-link-btn {
  color: #000000;
  font-size: 13px !important;
  font-weight: 700;
}

.map-location-input {
  border: 1px solid #cccccc;
  border-radius: 6px;
  background: transparent;
  width: 196px;
  height: 36px;
  font-size: 14px !important;
  padding-left: 35px;
}

.search-placeholder {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.pin-button {
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.pin-placeholder {
  height: 19px;
  width: 16px;
}

.fullscreen-btn {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 36px;
  width: 36px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.row-space-btwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full-screen-icon {
  width: 15px;
}

.analytics-google-map {
  width: 100%;
  height: 600px;
}

.total-offers-received-notifier {
  border: 1px solid #edeef0;
  padding: 15px 20px;
}

.total-offers-received-notifier div {
  background-color: #109cf1;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.total-offers-received-notifier img {
  height: 18px;
  width: 18px;
}

.total-offers-received-notifier p {
  color: white;
  margin: 0;
  margin-left: 3px;
}

.properties-container {
  padding: 15px 20px 0 20px;
  height: 535px;
  max-height: 535px;
  border-bottom: 1px solid #edeef0;
  overflow-y: scroll;
}

.properties-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.analytics-single-property {
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 4px;
  border: 1px solid #edeef0;
  margin-bottom: 18px;
  max-width: 300px;
}

.analytics-property-img {
  height: 160px;
  width: 100%;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.property-purchase-price {
  font-size: 26px !important;
  color: #999999;
  font-weight: 700;
  margin-bottom: 0px;
}

.total-offers-on-property {
  background-color: #ffc107;
  border-radius: 16px;
  display: flex;
  height: 27px;
  flex-direction: row;
  padding: 4px 10px;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.total-offers-on-property p {
  font-size: 13px !important;
  font-weight: bold;
  color: black;
  margin: 0;
}

.total-offers-on-property img {
  height: 18px;
  width: 18px;
  margin-right: 6px;
}

.total-offers-on-property p {
  color: #000000;
  font-weight: bold;
}

.analytics-property-inner-container {
  padding: 18px 10px;
}

.mt-20 {
  margin-top: 20px;
}

.closed {
  background-color: #000000;
  border-radius: 4px;
  height: 21px;
  padding: 1px 20px;
  color: white;
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 13px !important;
}

.property-txt {
  color: #999999;
  font-weight: 600;
  margin: 0;
}

.wrapped-flex-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 18px;
}

.property-main-features {
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  height: 28px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  margin-right: 5px;
  margin-bottom: 6px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.property-main-features img {
  width: 18px;
  height: 17px;
  margin-right: 6px;
}

.property-main-features p {
  color: #000000;
  margin: 0;
  font-size: 13px !important;
  font-weight: 600;
}

.review-property-offers {
  color: #40b15a;
  background-color: transparent;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
}

.review-property-offers-map {
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  background-color: #40b15a;
  padding: 10px;
  display: block;
  text-align: center;
}

.analytics-single-property:hover .review-property-offers {
  background-color: #28a745;
  border: 1.5px solid #28a745;
  color: white;
}

.analytics-single-property:hover .property-purchase-price {
  color: #ff214f;
}

.analytics-single-property:hover {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.analytics-single-property:hover .analytics-property-img {
  filter: none;
  -webkit-filter: grayscale(0);
}

.arrow-right-button {
  border: 0;
  background: white;
  height: 36px;
  width: 36px;
  position: absolute;
  top: 30%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
}

.arrow-right-unflipped {
  width: 14px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.arrow-right-flipped {
  width: 14px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.ease-in-n-out {
  transition: 0.75s;
  transition-timing-function: ease-out;
}

.map-draw-btn {
  border: 0;
  background: white;
  height: 40px;
  width: 76px;
  color: #109cf1;
  position: absolute;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  top: 20px;
  left: 20px;
  font-weight: 600;
}

.draw-map-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 73px;
  background-color: white;
  padding: 0 25px;
  position: absolute;
  z-index: 2;
  top: 0;
  align-items: center;
}

.blue-color-txt {
  color: #109cf1;
  font-weight: 600;
}

.draw-map-cancel-btn {
  height: 34px;
  width: 80px;
  background-color: transparent;
  color: #109cf1;
  border-radius: 4px;
  border: 1px solid #109cf1;
  margin-right: 8px;
  cursor: pointer;
}

.draw-map-apply-btn {
  height: 34px;
  width: 80px;
  background-color: #109cf1;
  color: white;
  border-radius: 4px;
  border: 1px solid #109cf1;
  cursor: pointer;
}

.draw-map-container p {
  margin: 0;
}

.custom-badge-padding {
  margin-left: 8px;
  margin-bottom: 0;
}

.analytics-property-filter {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAECAYAAACzzX7wAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABKSURBVHgBXcxBDQAgCAVQNosYwQhGsYEVaGIEI3C1hRE8e/PrYFPZ3sbggyOiARMavZWg7CZCB76WrIfZBl5DFUT78H08IVHehguNMgxq7iGOEwAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: 82px center;
  padding-right: 20px;
  color: rgba(34, 34, 34, 1);
  width: 90px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.analytics-property-filter option {
  color: rgba(34, 34, 34, 1);
}

.searchIcon {
  width: 18px;
  height: 19px;
}

.locationInput {
  max-height: 32px;
  max-width: 135px;
  width: 100%;
  border: none;
  border-radius: 4px;
  text-indent: 10px;
  font-size: 12px;
}

.locationInput::-webkit-input-placeholder {
  color: #222222;
}

.locationInput::placeholder {
  color: #222222;
}

.locationImg {
  color: #999999;
  width: 13px;
}

.mapInput {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  max-height: 32px;
  display: flex;
  padding: 0 10px;
  align-items: center;
}
.chartWrapper {
  background-color: #fff;
  width: 100%;
}
.chartWrapper-scroll {
  max-width: 100%;
  overflow-x: scroll;
  margin-right: 20px;
}
.chartWrapper-scroll-global {
  overflow-x: auto;
}
.chartWrapper-scroll::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.chartWrapper-scroll::-webkit-scrollbar-track {
  margin: 0 10px;
  background: #efefef;
}

.chartWrapper-scroll::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 2px;
}

.offer-status {
  padding: 3px 20px;
  background: #222222;
  border-radius: 6px;
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  border: none;
}

.property-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #999999;
}

.offers-count-title {
  font-weight: 700;
  font-size: 12px;
}

.calendar,
.withoutValue {
  min-width: 170px;
}

.upper-filters {
  padding-left: 20px;
  padding-right: 20px;
}

.calendar button {
  padding-left: 0;
}

.calendar .react-daterange-picker,
.calendar .react-daterange-picker__clear-button {
  padding: 0;
}

.calendar .react-daterange-picker__clear-button svg {
  width: 14px;
}

.calendar .react-daterange-picker__wrapper {
  border: none;
}
.calendar .react-daterange-picker__inputGroup {
  font-size: 10px;
}

.withoutValue .react-daterange-picker__wrapper {
  border: none;
}

.withoutValue .react-daterange-picker__inputGroup {
  font-size: 10px;
}

.withoutValue .react-daterange-picker__inputGroup input {
  display: none;
}

.withoutValue .react-daterange-picker__inputGroup span {
  display: none;
}

.withoutValue .react-daterange-picker__range-divider {
  display: none;
}

.scroll-cut {
  width: 29px;
  height: 13px;
  background: #fff;
  position: absolute;
  bottom: 19px;
  left: 30px;
}
.scroll-cut::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background: #fff;
  right: -0.8px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.scroll-cut::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background: #fff;
  right: -0.8px;
  bottom: -1.7px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.total-over-time-chart {
  width: 545px;
}

.react-select-container {
  height: 32px !important;
}

.react-select-groups > .css-bg1rzq-control {
  height: auto !important;
}

.css-bg1rzq-control {
  height: 32px !important;
  min-height: 0px !important;
  border-color: #ebebeb !important;
  border: 1px solid #ebebeb !important;
  border-radius: 6px !important;
}

.css-1wy0on6 {
  height: 32px !important;
}

.line-chart-width {
  max-width: 380px;
}

.summary-detail-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.all-client-offers-badge-width {
  width: auto !important;
}

.thankme-modal-header {
  border-bottom: 1px solid #f8f9fa !important;
  padding-top: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.thankme-modal-header p {
  padding: 0 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
}

.thankme-modal-header button {
  padding: 0 15px !important;
  margin: 0 !important;
}

.thank-me-buttons {
  background-color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #e9ecef;
  color: #109cf1;
}
.thank-you-btn {
  background-color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #e9ecef;
  color: #109cf1;
  height: 40px !important;
}

.thank-me-buttons-active {
  background-color: #109cf1;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.thankme-price-btn {
  width: 20%;
}

.thankme-textarea {
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.thank-me-continue {
  background-color: #109cf1;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  font-size: 12px;
  height: 40px !important;
}

.modal-content {
  border-radius: 6px;
}

.trend-text {
  font-size: 14px;
  color: #ff214f !important;
  text-decoration: underline;
}

.global-chart-trend-img {
  vertical-align: baseline !important;
  height: 12px !important;
  margin-right: 5px !important;
}

.view-trend-offers-btn {
  margin-bottom: 0px !important;
}

.tooltip {
  position: absolute;
  /* background-color: #333; */
  background-color: transparent;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  top: 35%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: inline-block;
  opacity: 0.8;
}

.info-icon-tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  cursor: pointer;
  font-weight: bold;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  width: 200px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
}

.tooltip-content-property-page {
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #000000;
  padding: 5px;
  width: 200px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
}

.my-analytics-container {
  background-color: #fff !important;
}

@media (max-width: 1440px) {
  .tab-responsive-flex-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .draw-map-container {
    height: 130px;
    padding: 0 10px;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
}

@media (max-width: 1250px) {
  .average-offers {
    font-size: 40px !important;
  }
}

@media (max-width: 1166px) {
  .total-received-offers-container {
    height: 357px;
  }
  .analytics-offer-details-btn {
    margin: 0;
    border-bottom: 0;
  }
  .total-offers-on-property {
    margin-bottom: 8px;
  }
  .custom-badge-padding {
    margin-bottom: 8px;
  }
  .closed {
    margin-bottom: 8px;
  }
}

@media (max-width: 1040px) {
  .analytics-google-map {
    position: relative !important;
  }
  .max-width-400 {
    max-width: 400px;
    margin: auto;
  }
  .total-offers-received-notifier {
    border: 0;
    margin-top: 15px;
  }
}

/* media max and min */
@media (max-width: 1026px) and (min-width: 576px) {
  .responsive-height {
    height: 107px !important;
  }

  .charts-main-container {
    height: 100% !important;
  }

  /* .global-chart-button-margin-bottom {
    margin-bottom: 0px !important;
  } */

  .global-chart-text-padding {
    padding: 0px !important;
  }

  .summary-detail-container {
    justify-content: flex-start;
  }
  .Analytic_exportButton__Exzyl {
    margin-bottom: 0px !important;
  }
  .Analytic_viewOffers__185Xo {
    margin-bottom: 0px !important;
  }

  .total-over-time-chart {
    width: 200px !important;
  }
  /* .line-chart-width {
  max-width: 100%;
} */
}

@media (max-width: 992px) {
  /* .global-chart-button-margin-bottom {
    margin-bottom: 0px !important;
  } */

  .total-over-time-chart {
    width: -webkit-fill-available;
  }

  .line-chart-width {
    max-width: 100%;
  }

  .tab-responsive-mr-15 {
    margin-right: 15px;
  }
  .tab-responsive-mb-30 {
    margin-bottom: 30px;
  }
  .analytics-table-left {
    padding: 20px !important;
  }
  .analytics-table-right {
    padding: 20px !important;
  }
  .maps-filters-container {
    border: 0;
  }
  .draw-map-container {
    left: 0 !important;
  }
}

@media (max-width: 576px) {
  .analytics-row {
    flex-direction: column;
  }

  .tooltip {
    position: absolute;
    /* background-color: #333; */
    background-color: transparent;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    /* bottom: 0px; */
    top: 0px;
    left: 0px;
    -webkit-transform: translate(-50%, -98%);
    transform: translate(-50%, -98%);
    display: inline-block;
    opacity: 0.8;
  }

  .analytics-single-property {
    max-width: 576px !important;
  }

  .properties-container {
    padding: 0px !important;
  }

  .global-chart-button-margin-bottom {
    margin-bottom: 20px !important;
  }

  .global-chart-text-padding {
    padding: 0px !important;
  }

  .tab-responsive-mr-15 {
    margin-right: 0px;
  }

  .chart-mr-left {
    margin-left: 0px;
  }

  .analytics-tabs-mobile {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .analytics-tabs-mobile > a {
    font-weight: bold !important;
  }

  .price-input-container {
    max-width: 40%;
  }
  .average-terms-container {
    margin-left: 30px;
  }
  .width-60 {
    width: 100%;
  }
  .my-data-global-data-mobile-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .my-data-global-data-mobile-btn > button {
    margin: 0 !important;
    width: 130px;
    background: #f8f8f8;
    border: 1px solid #ebebeb;
  }
  .my-data-global-data-mobile-btn > .gd-active {
    background-color: #109cf1 !important;
  }
  .summary-detail-container {
    flex-direction: column;
    margin-top: 16px;
    justify-content: initial;
    flex-wrap: inherit;
    align-items: initial;
  }
  .summary-detail-container > .Analytic_summaryDetails__2yEd_ {
    margin-bottom: 16px;
  }
  .summary-detail-container
    > .Analytic_summaryDetails__2yEd_
    > .Analytic_activeDetails__3sIRt,
  .summary-detail-container
    > .Analytic_summaryDetails__2yEd_
    > .Analytic_deActiveDetails__2uU8R {
    margin-bottom: 0;
    width: 50%;
    text-align: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
  }
  .mob-dropdown-style-width-100-perc,
  .mob-dropdown-style-width-100-perc > button,
  .mob-dropdown-style-width-100-perc > a,
  .mob-dropdown-style-width-100-perc > a > button {
    width: 100% !important;
  }
  .mob-dropdown-style {
    max-width: initial !important;
  }

  .mobile-tabs nav {
    display: -webkit-box !important;
    overflow-x: scroll !important;
    flex-wrap: nowrap !important;
  }

  .mobile-tabs nav::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 500px) {
  .map-draw-btn {
    top: 10%;
    left: 5%;
  }
}

@media (max-width: 480px) {
  .maps-filters-container {
    margin-top: -60px;
    margin-bottom: 60px;
  }
  .price-input-container {
    max-width: 95%;
  }
  .width-60 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .analytics-mobile-filters {
    margin: 5px 0;
    padding: 5px 0;
  }
  .mobile-responsive-map {
    margin-top: -50px;
  }
  .chartWrapper-scroll {
    width: 100% !important;
  }
}

@media (max-width: 428px) {
  .map-draw-btn {
    left: 7%;
  }
  .draw-map-container {
    height: 200px;
    padding: 0 10px;
    flex-direction: column;
    padding: 15px 15px 5px;
    text-align: center;
    left: 0 !important;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
}

@media (max-width: 320px) {
  .mobile-responsive-map {
    margin-top: -100px;
  }
  .price-input-container {
    max-width: 93%;
  }
  .chart-title-btn {
    font-size: 12px !important;
  }
  .maps-filters-container {
    margin-top: -100px;
    margin-bottom: 60px;
  }
}

.active-bg {
  background: #017c42;
  border-radius: 5px;
  color: #fff !important;
}

.search-icon-offers-table {
  height: auto;
  width: 20px;
  margin-left: 5px;
}

.search-bar-offers-table {
  text-decoration: underline !important;
  text-underline-offset: 5px !important;
}

.main-container {
  background-color: #fff;
  padding: 20px 10px 20px 10px;
}

.secondary-container {
  background-color: #f6f7fa;
  padding: 20px 10px 20px 10px;
}

.mobile-export-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 32% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-view-details-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 15px !important;
  /* width: 110px !important; */
  width: auto !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-view-offer-btn {
  background: #109cf1;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-btns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile-summary-details {
  height: 40px !important;
}

.mobile-summary-details button {
  align-items: center !important;
  width: 50% !important;
  margin-bottom: 0px !important;
  justify-content: center !important;
}

.mobile-active-details {
  padding: 6px 9px !important;
}

.mobile-verified-container {
  background: #f8f8f8;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
}

.mobile-verified-container button {
  background-color: rgb(16, 156, 241);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  font-size: 12px;
  padding-left: 16px !important;
  padding-right: 16px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin-bottom: 0px !important;
}

.mobile-analytics-filters-modal {
  background-color: #f6f7fa;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
  height: 100%;
  width: 100%;
}

.mobile-filters-cancel-btn {
  background: #fff;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-global-data-btn {
  background: #ebebeb;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-filters-footer-container {
  background-color: #fff;
  padding: 20px 10px 10px 10px;
  /* margin-top: 59vh !important; */
  margin-top: calc(100vh - 369px) !important;
}

.mobile-global-data-modal {
  background-color: #f6f7fa;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #222222;
  /* height: 100%; */
  width: 100%;
}

.mob-common-close-btn-container button {
  margin-bottom: 0px !important;
}

.mob-global-offers-modal-container {
  height: 256px !important;
}

.thankme-modal {
  min-width: 350px !important;
}

.global-my-data-btn {
  background: #ededed;
  color: #109cf1;
  /* border-radius: 4px; */
  border: none;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  margin-bottom: 1px !important;
}

.global-global-btn {
  background: #ededed;
  color: #109cf1;
  /* opacity: 1; */
  text-transform: none;
  /* border-radius: 4px; */
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ebebeb;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.global-cancel-btn {
  background: #fff;
  color: #000;
  /* opacity: 1; */
  text-transform: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid lightgray;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-my-active-btn {
  background: #109cf1;
  color: #ededed;
  /* opacity: 1; */
  text-transform: none;
  /* border-radius: 4px; */
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #109cf1;
  margin-bottom: 0px !important;
  width: 100% !important;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.global-data-modal-mobile .modal-content {
  background-color: transparent !important;
  border: none !important;
}

.thank-you-modal {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.mobile-icons {
  width: 16px !important;
  height: 16px !important;
}

.mob-filters-container span {
  color: #999999 !important;
}

.thankme-date-input {
  height: 42px !important;
}

.thankme-modal-back-btn {
  font-size: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.mob-filter-date-picker-icon {
  margin-right: 5px !important;
}
.global-data-heading{
  width: auto !important;
}

@media screen and (max-width: 576px) {
  .mob-locationDetailsChart .chartjs-render-monitor {
    -webkit-animation: chartjs-render-animation 0.001s;
            animation: chartjs-render-animation 0.001s;
    width: 100% !important;
    height: 300px !important;
  }

  .modal:has(.thankme-modal) {
    position: fixed !important;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .thankme-modal {
    min-width: 0px !important;
  }
}

.style_dropdown__1XS8M button {
  display: flex;
  align-items: center;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
  font-weight: 400;
  font-size: 14px;
  background: unset;
  padding-left: 6px;
  border: none;
  margin: 0;
  padding-top: 6px;
  cursor: pointer;
}

.style_container__w6wop {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 20px;
}

.style_container__w6wop > ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.style_container__w6wop > ::-webkit-scrollbar-track {
  background: #efefef;
}

.style_container__w6wop > ::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.totalOverListingsChart_container___ror5 {
  padding: 20px;
  margin: 30px 0;
  background-color: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.totalOverListingsChart_container___ror5 > ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.totalOverListingsChart_container___ror5 > ::-webkit-scrollbar-track {
  background: #efefef;
}

.totalOverListingsChart_container___ror5 > ::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.Analytic_analyticOne__QmNA2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  font-family: "Raleway";
}

.Analytic_MuiBox-root__3QhML .Analytic_MuiBox-root-16__38WxH {
  padding: 0 !important;
}

.Analytic_analyticTitle__21RNQ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 18px 0 20px;
  background-color: #ffffff;
}

.Analytic_overviewLinks__2PQdI a {
  padding-left: 15px;
  color: #727272;
  font-size: 12px;
}

.Analytic_chartBlock__3n8Wg {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Analytic_oneChart__3ljoE {
  display: flex;
  width: 100%;
  grid-gap: 15px;
  gap: 15px;
}

.Analytic_totalOffersListings__2cqtW {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.Analytic_oneChart__3ljoE > div {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.Analytic_DoughuntChartBlock__2Io0u {
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.Analytic_verifyOffers__3Kz8h {
  width: 196px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
  justify-content: space-between;
  border-radius: 4px;
  padding: 20px 20px;
  border: 1px solid #ebebeb;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #222222;
  padding-left: 20px !important;
}

#Analytic_Order_Location__1dPGP {
  /*padding-right:20px;*/
}

.Analytic_go-btn___emh4 {
  position: absolute;
  right: 0;
  top: 0;
}

.Analytic_summary_span__2no3b > span {
  color: #ffffff !important;
  line-height: 14px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.Analytic_summary_span__2no3b > span:focus {
  color: white !important;
}

.Analytic_overviewButton__bSgtE {
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 14px;
  gap: 14px;
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  line-height: 30px;
}
/*
.light>span {
  background-color: #109CF1;
  color: #fff !important;
  line-height: 14px;
  font-size: 12px;
  color: white !important;
  height: 24px;
  align-items: center;
}

.dark>span {
  background-color: #EBEBEB;
  color: #727272 !important;
  line-height: 14px;
  font-size: 12px;
  color: white !important;
  height: 24px;
  align-items: center;
} */

.Analytic_activeDetails__1joU4 {
  background-color: #109cf1;
  color: #ffffff;
  padding: 3px 9px;
  font-weight: 600;
}

.Analytic_deActiveDetails__3IgTe {
  background: unset;
  text-transform: none;
  padding: 2px 10px;
  font-weight: 500;
  text-align: center;
  color: #727272;
  font-weight: 500;
}

.Analytic_summaryDetails__2dJ2z > button {
  border-radius: 2px;
  border: none;
  font-size: 12px;
}

.Analytic_summaryDetails__2dJ2z {
  background: #f8f8f8;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  padding: 4px;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  font-size: 12px;
}
a {
  color: #727272;
}

.Analytic_summaryDetailsBlock__JK56r {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  padding: 4px;
  flex: none;
  flex-grow: 0;
  align-items: center;
}

.Analytic_summaryDetailsBlock__JK56r button {
  display: flex;
  align-items: center;
}

.Analytic_bokeragesSelect__3EiRQ {
  height: 40px !important;
  padding: 8px;
  width: 139.5px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.Analytic_analyticTitle__21RNQ input {
  height: 41px !important;
}

.Analytic_dateRange__aBdR8 {
  border: none;
  padding: 5px 10px;
  height: 30px;
  width: 150px;
}

.Analytic_dateRangeTitle__1vnno {
  position: absolute;
  top: 8px;
  background: #ffffff;
  width: 118px;
  text-align: center;
  color: #999999;
  font-size: 12px !important;
  padding-right: 27px;
  height: 23px;
  left: 6px;
  z-index: 1;
  font-weight: 500;
}

.Analytic_priceSelect__3kLOv {
  height: 40px !important;
  padding: 8px;
  width: 139.5px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.Analytic_tabs__38bZo a {
  background: unset !important;
  border: none !important;
}

.Analytic_tabs__38bZo {
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;
  row-gap: 24px;
}

.Analytic_tabs__38bZo a:hover,
.Analytic_tabs__38bZo a:focus,
.Analytic_customTabActive__3eKpQ a:first-child,
.Analytic_customTabActive1__1Vvk_ a:nth-child(2),
.Analytic_customTabActive2__2Wsyc a:nth-child(3),
.Analytic_customTabActive3__34CXY a:nth-child(4) {
  color: rgba(16, 156, 241, 1) !important;
  border: none !important;
  border-bottom: 2px solid rgba(16, 156, 241, 1) !important;
}

.Analytic_globalData__2GtU2 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column-gap: 8px;
  -webkit-column-gap: 8px;
          column-gap: 8px;
}

.Analytic_width15__2_avk {
  background: #ffffff;
  max-height: 34px;
  border-radius: 4px;
  width: 160px;
}
.Analytic_width17__1pWlI {
  background: #ffffff;
  max-height: 34px;
  border-radius: 4px;
  width: 175px;
}
.Analytic_width15__2_avk button {
  border-radius: 4px;
}

.Analytic_width180__2T9cJ {
  width: 210px;
}

.Analytic_width15dateRange__Bc_Ph {
  position: relative;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}

.Analytic_width15dateRange__Bc_Ph input {
  height: 32px;
  padding-bottom: 4px;
  border-radius: 4px;
}
.Analytic_width15dateRange__Bc_Ph input::-webkit-input-placeholder {
  color: #999999;
  font-size: 12px;
}
.Analytic_width15dateRange__Bc_Ph input::placeholder {
  color: #999999;
  font-size: 12px;
}

.Analytic_exportButton__3SqNk {
  background: #fff;
  color: #109cf1;
  opacity: 0.5;
  text-transform: none;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  border-radius: 4px;
  max-height: 32px;
  font-weight: 600;
  border: 1px solid #ebebeb;
}

.Analytic_exportParent__htfiX {
  width: 10%;
  border: none;
  display: flex;
  align-items: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  height: calc(100% - 10px);
  grid-column-gap: 42px;
  -webkit-column-gap: 42px;
          column-gap: 42px;
}

.Analytic_viewOffers__1IsXY {
  background: #109cf1;
  color: #ffffff;
  width: 100px;
  text-transform: none;
  padding: 9px 16px;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  max-height: 32px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
}

.Analytic_tabsParent__uGnjx {
  border-bottom: 1;
  border-color: divider;
}
.Analytic_tabsParent__uGnjx a {
  padding: 5px 1rem;
  padding-left: 10px;
  padding-right: 10px;
}

.Analytic_locationImg__1esbF {
  position: absolute;
  top: 28%;
  right: 6%;
  color: #999999;
  width: 13px;
}

.Analytic_dropdownStyle__2xFra {
  background: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  width: 100%;
  max-width: 240px;
}

.Analytic_dropdownStyle__2xFra button {
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-size: 12px;
  margin: 0;
  background: unset;
  font-weight: 500;
}

.Analytic_dropdownStyle__2xFra img {
  width: 9px;
  margin-left: 4px;
}

.Analytic_details__1vyi_ {
  color: #999999;
}

.Analytic_locationInput__3EuUC {
  height: 41px;
  width: 140px;
  border: none;
  border-radius: 4px;
  text-indent: 16px;
}

.Analytic_locationInput__3EuUC::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 500;
}

.Analytic_locationInput__3EuUC::placeholder {
  font-size: 14px;
  font-weight: 500;
}

.Analytic_dateRangBlock__1SVze {
  position: relative;
  float: left;
  border-radius: 4px;
}

.Analytic_calendarBtn__eWHFF {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 25px;
  height: 25px;
  background: #fff;
  pointer-events: none;
}

.Analytic_calendarBtn__eWHFF button {
  border: none;
  background: transparent;
}

.Analytic_line__300pi {
  height: 32px;
  background: #ebebeb;
  width: 1px;
}
.Analytic_title__21c80 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #222222;
  margin-bottom: 0;
}
.Analytic_wrapper__3smci {
  display: flex;
  align-items: baseline;
  grid-gap: 8px;
  gap: 8px;
}
.Analytic_btnImg__11m8l {
  border: none;
  background-color: #fff;
}
.Analytic_btnImg__11m8l img {
  min-height: 34px;
}

.Analytic_vector__359-7 {
  align-self: flex-start;
  display: block;
  margin-bottom: 16px;
}

.Analytic_ratioDetails__2qlqt {
  width: 100%;
  background: #ffffff;
}

.Analytic_ratioDetails__2qlqt::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.Analytic_ratioDetails__2qlqt::-webkit-scrollbar-track {
  background: #efefef;
}

.Analytic_ratioDetails__2qlqt::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.Analytic_range__jpGGd {
  display: none;
}

.Analytic_rangeDisplay__2h8IV {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #999999;
  top: 10px;
  left: 10px;
}

.Analytic_labels__26h-n {
  display: flex;
  align-items: center;
  grid-column-gap: 3px;
  -webkit-column-gap: 3px;
          column-gap: 3px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.Analytic_circle__1HCSY, .Analytic_circle1__1TQLs {
  width: 17px;
  border-radius: 10px;
  height: 17px;
  margin-left: 7px;
}

.Analytic_circle__1HCSY:first-child, .Analytic_circle1__1TQLs:first-child {
  background: #28a745;
}

.Analytic_circle__1HCSY:nth-child(3) {
  background: red;
}

.Analytic_circle__1HCSY:nth-child(5) {
  background: #ffc107;
}


.Analytic_circle1__1TQLs:nth-child(3) {
  background: #109CF1;
}

.Analytic_text-decoration-line-through__2H7rE {
  text-decoration: line-through;
}

.Analytic_globalRatioDetails__34l21 {
  min-height: 450px;
}


.globalPriceRatio_globalPriceRatio__20iMT {
  width: 100%;
  overflow-x: auto;
}
.globalPriceRatio_globalPriceRatio__20iMT::-webkit-scrollbar {
  height: 8px;
}

.globalPriceRatio_globalPriceRatio__20iMT::-webkit-scrollbar-track {
  background: #efefef;
}

.globalPriceRatio_globalPriceRatio__20iMT::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 2px;
}

.globalPriceRatio_roundDiagrams__36b1D div {
  /* gap: inherit !important;
  column-gap: 45px !important; */
  /* padding: 0 !important; */
}

.globalPriceRatio_roundDiagrams__36b1D {
  padding: 70px 0 25px 0;
}

.globalPriceRatio_labels__2HxI8 {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  grid-column-gap: 4px;
  -webkit-column-gap: 4px;
          column-gap: 4px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
}

.globalPriceRatio_circle__1Szo9, .globalPriceRatio_circle3__3WG95 {
  width: 17px;
  border-radius: 10px;
  height: 17px;
}

.globalPriceRatio_circle__1Szo9:first-child {
  background: #28a745;
}

.globalPriceRatio_circle__1Szo9:nth-child(3) {
  background: #109CF1;
  margin-left: 12px;
}

.globalPriceRatio_circle__1Szo9:nth-child(5) {
  background: #FFC107;
  margin-left: 12px;
}

.globalPriceRatio_circle3__3WG95:first-child {
  background: #28a745;
}

.globalPriceRatio_circle3__3WG95:nth-child(3) {
  background: #EB3E54;
  margin-left: 18px;
}

.globalPriceRatio_circle3__3WG95:nth-child(5) {
  background: #FFC107;
  margin-left: 18px;
}

.globalPriceRatio_text-decoration-line-through__3Jagy {
  text-decoration: line-through;
}

.GlobalLocation_title__2u5sV {
    font-family: "Raleway";
    font-weight: 600;
    line-height: 21px;
    color: #222222;
  }
  
  .GlobalLocation_locationDetailsChart__2qmP9 {
    width: 50%;
    height: 390px;
    overflow-y: scroll;
  }
  
  
  .GlobalLocation_locationDetailsChart__2qmP9::-webkit-scrollbar {
    width: 8px;
  }
  
  .GlobalLocation_locationDetailsChart__2qmP9::-webkit-scrollbar-track {
    background: #efefef;
  }
  
  .GlobalLocation_locationDetailsChart__2qmP9::-webkit-scrollbar-thumb {
    background-color: #109cf1;
    border-radius: 5px;
  }
  
.locationSummary_title__20iKn {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 21px;
  color: #222222;
}

.locationSummary_locationDetailsChart__1WTsx {
  width: 49%;
  height: 350px;
}

.locationSummary_locationDetailsChart__1WTsx::-webkit-scrollbar {
  width: 8px;
}

.locationSummary_locationDetailsChart__1WTsx::-webkit-scrollbar-track {
  background: #efefef;
}

.locationSummary_locationDetailsChart__1WTsx::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

.locationSummary_mob-locationDetailsChart__35bUS {
  width: 100%;
  height: 350px;
}

.locationSummary_mob-locationDetailsChart__35bUS::-webkit-scrollbar {
  width: 8px;
}

.locationSummary_mob-locationDetailsChart__35bUS::-webkit-scrollbar-track {
  background: #efefef;
}

.locationSummary_mob-locationDetailsChart__35bUS::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 5px;
}

@media screen and (max-width: 576px) {

}

.PriceRatio_chartContainer__3NbE6 {
  position: relative;
}

.PriceRatio_chartContainer__3NbE6::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid black;
  pointer-events: none;
}

.PriceRatio_labels__GSYe3 {
  display: flex;
  align-items: center;
  grid-column-gap: 3px;
  -webkit-column-gap: 3px;
          column-gap: 3px;
  font-family: "Raleway";
  font-weight: 500;
  font-size: 12px;
  color: #727272;
  margin-top: 10px;
}

.PriceRatio_circle__3yhn-,
.PriceRatio_circle2__2X-Df,
.PriceRatio_circle4__RBM5G {
  width: 17px;
  border-radius: 10px;
  height: 17px;
  margin-left: 12px;
}

.PriceRatio_circle__3yhn-:first-child {
  background: #28a745;
}

.PriceRatio_circle__3yhn-:nth-child(3) {
  background: #109cf1;
}

.PriceRatio_circle2__2X-Df:first-child {
  background: #EBEBEB;
}

.PriceRatio_circle2__2X-Df:nth-child(5) {
  background: #109cf1;
}

.PriceRatio_circle2__2X-Df:nth-child(3) {
  background: #28a745;
}

.PriceRatio_text-decoration-line-through__ZV2Yb {
  text-decoration: line-through;
}

.PriceRatio_circle4__RBM5G:first-child {
  background: #EBEBEB;
}

.PriceRatio_circle4__RBM5G:nth-child(3) {
  background: #28a745;
}

.PriceRatio_circle4__RBM5G:nth-child(5) {
  background: #109cf1;
}

.dashboard-content h3 {
  color: #222222;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 22px !important;
}
p,
span,
h4,
h5 {
  font-family: "Raleway", sans-serif;
}

.margin-0 {
  margin: 0;
}

.house {
  text-align: left;
}

.bg-clr {
  background: #f6f7fa;
}
.bg-clr-white {
  background: white;
  box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.house img {
  width: 100%;
  height: auto;
  max-height: 300px !important;
}

.offer-comments-filter-container {
  flex-direction: row;
  justify-content: flex-end;
}

.offer-comments-filter {
  min-height: 32px !important;
  padding: 0 !important;
  margin: 8px;
  margin-right: 0;
  margin-left: 0;
  border-radius: 5px;
  background: transparent;
  border: 1px solid black;
  cursor: pointer;
}

.dashboard-list {
  padding: 30px 15px;
}

.offer {
  color: #999999;
  font-size: 16px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.in-review button {
  background: #ffc107;
  color: #222222;
}

.seller-review button {
  background: #fd8d14;
  color: #fff;
}

.accepted button {
  background: #28a745;
  color: #ffffff;
}

.declined button {
  background: #dc3545;
  color: #ffffff;
}

.executed button {
  background-color: #0a8c77;
  color: white;
}

.withdrawn button {
  background-color: #000;
  color: white;
}

.in-review button,
.accepted button,
.declined button,
.executed button,
.seller-review button,
.withdrawn button {
  border-radius: 4px;
  outline: 0;
  border: 0;
  font-size: 12px !important;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  height: 18px;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}

.in-review button img,
.accepted button img,
.declined button img,
.executed button img,
.seller-review img,
.withdrawn button img {
  padding-right: 5px !important;
  margin-top: -3px !important;
}

.homeoffer-details {
  padding-left: 0;
}

.homeoffer-details h5 {
  color: #222222;
  font-family: "Raleway";
  font-weight: 700;
  font-size: 20px !important;
  margin: 16px 0;
}

.homeoffer-details ul li img {
  margin-right: 5px;
}

.offfer-status-rendering-container {
  max-width: 87%;
}

.homeoffer-details ul li p {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #999999;
  font-size: 16px !important;
  display: inline-block;
}

.homeoffer-details ul li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.homeoffer-details ul li p span {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: #222222;
  font-size: 16px !important;
}

.underline {
  text-decoration: underline;
}

.black-cursor-pointer {
  color: #222222 !important;
  cursor: pointer;
  margin-right: 0 !important;
}

.homeoffer {
  background-color: #e9ecef;
  padding: 30px;
  padding-bottom: 16px;
}

.homeoffer p {
  color: #222222;
  font-size: 16px !important;
  font-weight: 500;
  margin-bottom: 5px;
}

.is-viewed {
  font-size: 13px;
  margin-left: 5px;
}

.black-price-tag {
  color: #222222 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.red-price-tag {
  color: #dc3545 !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

.back-btn {
  padding: 0 30px;
  background-color: white;
  border: 1px solid #cccccc;
  font-size: 18px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 8px;
}

.backArrow {
  margin-right: 7px;
}

.green-btn.no-margin {
  margin-right: 0px;
}

.green-btn {
  padding: 0 30px;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 8px;
}

.green-btn:disabled {
  opacity: 0.65;
}

.btn-review {
  padding: 0 30px;
  background-color: white;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: #28a745;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.red-btn {
  padding: 0 30px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}
.other-agent-action-btn {
  padding: 0 15px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  font-size: 12px !important;
  color: white;
  border-radius: 5px;
  height: 32px;
  cursor: pointer;
}

.otheragent-header-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.red-btn:disabled {
  opacity: 0.65;
}

.mt-95 {
  margin-top: 95px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(233, 236, 239, 0.5);
}

.table-striped tbody tr:nth-of-type(odd) td {
  background-color: rgba(233, 236, 239, 0.5);
  padding: 6px 10px !important;
}
.table-striped tbody tr:nth-of-type(even) td {
  padding: 6px 10px !important;
}

.table td,
.table th {
  border: 0;
}

.bold-td {
  font-size: 16px !important;
  font-weight: bold;
  color: #222222;
  padding-left: 30px !important;
  height: 60px;
}

.normal-td {
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  height: 60px;
}

.table td,
.table th {
  /* padding: 0 30px;
  height: 60px;
  vertical-align: middle;
  width: 55%; */
  height: 60px;
  vertical-align: middle;
}

.mt-40 {
  margin-top: 40px;
}

.mb-13 {
  margin-bottom: 13px;
}

.offer-package {
  margin-top: 20px;
}

.offersMian {
  font-weight: bold;
  font-size: 22px !important;
  color: #222222;
  font-family: "Raleway", sans-serif;
}

.uploadBtn {
  border: 1px solid #222222;
  color: #222222;
  background-color: white;
  padding: 0 30px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  margin-top: 23px;
  height: 44px;
}

.history-of-changes {
  font-weight: bold;
  font-size: 22px;
  color: #222222;
  font-family: "Raleway", sans-serif;
  margin-bottom: 30px;
  margin-top: 21px;
}

.history-changes {
  background: white;
  padding: 8px 30px;
  border-left: 3px solid #b0b0b0;
}

.history-changes p {
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  margin: 12px 0;
}

.review p {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}

.review-text {
  font-family: "Raleway", sans-serif;
  font-size: 16px !important;
  font-weight: 500;
  color: #222222;
  margin-bottom: 40px;
}

.m-57 {
  margin-top: 57px;
  margin-bottom: 57px;
}

.summaries {
  font-weight: bold;
  font-size: 22px;
  color: #222222;
  font-family: "Raleway", sans-serif;
  padding-top: 40px;
}

.accordian {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.accordian-opened {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
}

.accordian-opened p {
  margin: 30px 0;
  font-size: 16px !important;
  color: #222222;
  font-weight: bold;
}

.accordian p {
  margin: 30px 0;
  font-size: 16px !important;
  color: #222222;
  font-weight: bold;
}

.accordian-p {
  font-size: 16px !important;
  color: #222222;
  font-weight: 500;
  padding: 8px;
}

.p-lr-30 {
  padding: 0 30px;
}

.card {
  border: 0;
}

.card-body {
  padding: 0;
}

.mb-457 {
  margin-bottom: 457px;
}
.space-between {
  justify-content: space-between;
}
.row {
  display: flex;
  flex-direction: row;
}

.m-lr-0 {
  margin: 0;
}

.dashboard-content {
  padding-bottom: 10px;
}

.terms-and-conditions-heading {
  font-weight: 900;
  color: #000;
}

.terms-and-conditions ul {
  list-style: auto;
  margin-left: 15px;
}

.terms-and-conditions ul li {
  margin-bottom: 8px;
}

.offer-detail-actions {
  padding: 8px 15px;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 18px !important;
  color: black;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.flexEnd {
  align-items: flex-end;
}

.btn-list-margin {
  margin: 2px 0;
}

.padd-horizontal-15 {
  padding: 0 15px;
}

.action-btns {
  position: absolute;
  right: 15px;
  background: white;
  z-index: 10;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  padding: 15px;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
}

.agent-brokerage {
  font-size: 20px !important;
  font-weight: 600;
  color: #000000;
}

.mt-10 {
  margin-top: 10px !important;
}

.modal-body label {
  margin: 20px 0;
}

.accept-comment {
  width: 100% !important;
  /* margin: 20px 0px !important; */
  /* margin-top: 20px !important; */
}

.accept-comment-container {
  margin: 20px 0px !important;
}

.code-container {
  position: relative;
}

.embedable-code-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.all-users-heading {
  margin-bottom: 0;
}

.invite-user-heading {
  text-align: center;
  font-weight: 700;
  font-size: 22px !important;
  color: black;
}

.users-table-data {
  vertical-align: middle;
  font-size: 14px !important;
  margin: 0;
  font-weight: initial;
}

.row-spaceBtwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.invite-user-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 20px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.align-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user-profile-btn {
  font-weight: 600;
  font-size: 14px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.offer-preview-container {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 30px;
}

.offer-preview-bg {
  background-color: #ffffff !important;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.verify-offer-btn {
  padding: 8px 20px;
  background-color: #ff214f;
  border: 1px solid #ff214f;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-left: 8px;
}

.display-flex {
  display: flex;
}

.name-change-input{
  width: 350px !important;
}

.doc-timestamp {
  font-size: 11px !important;
  margin: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mb2 {
  margin-bottom: 2px;
}

.video-icon {
  width: 18px;
  height: 12px;
}

.counter-offer-table-text-green {
  font-size: 12px !important;
  color: green !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.counter-offer-table-text-red {
  font-size: 12px !important;
  color: red !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.text-red {
  color: red !important;
}

.mob-only-show-offer-trends-btn {
  display: none;
}

.archive-img {
  width: 17px;
}

.offer-details-ai-score-card-container {
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  color: white;
  box-shadow: 0 0 0 3px rgba(164, 69, 255, 1);
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 56px;
  font-size: 16px;
  padding: 0 4px;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 55px;
  height: 55px;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 31px;
  top: 13px;
}

.termination-commnet-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 0;
  color: #999999;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}

.termination-comment {
  color: #999999;
  background: #f4f4f4;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
}

.row-flex-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.metric-card {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.metric-card p {
  font-size: 14px;
  margin-bottom: 0;
}

.metric-value {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: bold;
}

.metric-card h6 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.amend-offers-toogle {
  justify-content: center !important;
  margin-bottom: 16px;
}

.amend-offers-toogle label {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.amend-offers-toogle input[type="checkbox"] {
  display: none;
} 

.agent-info-progress {
  height: 18px;
  background-color: #e9ecef;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 4px;
  position: relative;
}

.agent-progress-bar {
  height: 18px;
  font-size: 14px;
  font-weight: bold;
  transition: width 0.3s ease-in-out;
}

.agent-info-custom-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: 13px;
  color: #6c757d;
} 

.amend-notifications-toogle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amend-notifications-toogle label {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 10px;
}

.amend-terms-modal-table {
  margin-bottom: 0;
}

.net-sheet {
  width: 13px;
}

.seller-net-footer {
  border-top: 0;
}

.seller-net-sheet-form-container {
  background: #f6f7f9;
  border: 1px solid #d4d7e0;
  border-radius: 8px;
  padding: 24px;
  margin: 50px 0 20px;
}

.seller-net-sheet-form-container label {
  margin: 0 0 4px 0;
  font-size: 16px;
}

.seller-net-sheet-form-container input {
  padding: 0 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.seller-net-sheet-form-container button {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
  background-color: #ff214f;
  border: 0;
  font-weight: bold;
  font-size: 16px;
}

.seller-net-doughnut-container {
  width: 320px; /* Ensures a perfect square container */
  height: 320px;
  margin: 0 auto;
  position: relative;
}

.seller-net-doughnut-container canvas {
  width: 320px !important;
  height: 320px !important;
  display: block;
}

.seller-net-doughnut-container .chart-center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
}

.seller-net-doughnut-container .chart-center-text strong {
  font-size: 24px;
}

.seller-net-doughnut-container .chart-center-text p {
  font-size: 14px;
  color: #6c757d;
  margin: 0;
}

.net-sheet-summary {
  margin-top: 20px;
  width: 100%;
}

.net-sheet-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 16px;
}

.net-sheet-label {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.net-sheet-value {
  font-weight: bold;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.net-sheet-total {
  font-size: 18px;
  font-weight: bold;
}

.net-sheet-result-container {
  border: 1px solid #d4d7e0;
  border-radius: 8px;
  padding: 24px;
  position: relative;
}

.tab-button-group {
  display: flex;
  justify-content: center;
}

.tab-button {
  background: none;
  border: none;
  margin-bottom: 0;
  color: grey;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  padding: 12px 16px;
  transition: color 0.3s ease;
}

.tab-button.active {
  color: #dc3545;
  font-weight: bold;
  margin-bottom: 0;
  border-bottom: 2px solid #dc3545;
}

.tab-button:hover {
  color: #dc3545;
}

.placeholder-content {
  text-align: center;
  padding: 30px;
}

.net-sheet-result {
  font-size: 45px;
}

.net-sheet-edit-btn {
  cursor: pointer;
  margin-bottom: 0;
}

.net-sheet-edit-input {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background: transparent;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #212529;
  outline: none;
}

.seller-net-sheet-description {
  margin-bottom: 0;
}

.refresh-icon {
  width: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.show-hide-button {
  margin-top: 10px;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
}

.show-hide-button:hover {
  background-color: #0056b3;
}

.detailed-closing-costs {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
}

.closing-cost-section {
  margin-top: 10px;
}

.cost-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.cost-item span {
  font-weight: bold;
}

.tax-toggle-container {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.toggle-option {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.tax-toggle-container input[type="radio"] {
  border: none;
  outline: none;
}

.save-estimate-btn {
  cursor: pointer;
  padding: 0;
  letter-spacing: 1px;
  color: #d94b57;
  border: 1px solid #d94b57 !important;
  outline: none;
  background-color: transparent !important;
  margin-top: 16px;
  height: 34px;
}

.estimate-item {
  background: #f6f7f9;
  border-radius: 8px;
  padding: 12px;
  margin: 6px 0;
  cursor: pointer;
}

@media (max-width: 1210px) {
  /* .btn-review {
    margin-right: 10px;
  } */
  button {
    margin-bottom: 20px;
  }
  .homeoffer-details button {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .homeoffer-details {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .offer-comments-filter-container {
    flex-direction: column;
  }
  .mobile-responsive-alert {
    font-size: 14px;
    padding: 10px;
    flex-wrap: wrap !important;
  }

  .bold-td {
    width: 50% !important;
  }

  .house img {
    width: 100%;
    object-fit: contain;
  }
  .homeoffer-details ul li {
    margin-left: 15px;
  }
  .btns-mobile-center {
    justify-content: center !important;
    text-align: center !important;
  }
  .flexEnd {
    align-items: center;
  }
  .mt-95 {
    margin-top: 55px;
  }
  .mb-457 {
    margin-bottom: 200px;
  }
  .overflow {
    overflow: hidden;
    padding: 0;
  }
  .view-counter-offer {
    min-height: 44px;
    height: auto;
  }
  .otheragent-header-btns {
    flex-direction: column;
    align-items: center;
  }
  .otheragent-header-btns button {
    margin-bottom: 10px !important;
  }
  .mob-only-show-offer-trends-btn {
    display: block;
    font-family: "Raleway", sans-serif;
    min-height: 32px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    color: #28a745;
    font-size: 12px;
    font-weight: 600;
    background-color: white;
    cursor: pointer;
    border: 1px solid #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-bottom: 26px;
  }
  .mob-only-show-offer-trends-btn img {
    width: 14px;
    margin-right: 6px;
  }
  .seller-net-doughnut-container {
    width: 250px;
    height: 250px
  }
  .seller-net-doughnut-container canvas {
    width: 250px !important;
    height: 250px !important;
  }
  .net-sheet-result {
    font-size: 35px;
  }
  .refresh-icon {
    top: 14px;
    right: 14px;
  }
  .tab-button {
    padding: 12px 6px;
  }
  .seller-net-mob-form-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .seller-net-sheet-description {
    margin: 20px 0;
  }
  .seller-net-sheet-form-container {
    margin-top: 20px;
  }
  .mobile-offer-term-buttons {
    flex-direction: column;
  }
  .mobile-offer-term-buttons button {
    margin-right: 0 !important;
  }
  .mobile-offer-term-buttons span button {
    width: 100%;
    margin-bottom: 10px;
  }
  .mob-only-show-offer-trends-btn {
    width: 100%;
  }
}
.offer-title-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -15px;
}

.offer-title-section h2 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #222222;
}

.follow-my-listing-btn {
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ebebeb;
}

.next-prev-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #28a745;
  width: auto !important;
  /* width: 102px !important; */
}

.next-prev-skip-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #ff214f;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #ff214f;
  width: auto !important;
  /* width: 102px !important; */
}

.comment-suggestions-btn {
  font-family: "Raleway", sans-serif;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
  border: 1px solid #28a745;
  /* width: 102px !important; */
}

.follow-my-listing-btn img {
  margin-right: 9.33px;
}

.create-spreadsheet-btn {
  color: white;
  background-color: #28a745;
  border: 1px solid #28a745;
  height: 32px;
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.offers-cards {
  margin: 30px 0;
}

.offers-stats-card-container {
  padding: 0 5px;
}

.offers-stats-card {
  background-color: white;
  border: 1px solid #ebebeb;
  padding: 16px 20px;
  border-radius: 4px;
}

.offer-stats {
  display: flex !important;
  flex-direction: row !important;
  justify-items: center !important;
}

.offer-stats h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #222222;
  margin-bottom: 0;
}

.font-15 {
  font-size: 15px !important;
}

.offers-stats-card p {
  color: #727272;
  font-size: 12px !important;
  font-weight: 500;
  font-family: "Raleway", sans-serif !important;
  margin-bottom: 6px;
}

.offer-stats p {
  color: #109cf1 !important;
  font-size: 10px !important;
  font-family: "Poppins", sans-serif !important;
  background: rgba(16, 156, 241, 0.1);
  border: 1px solid rgba(16, 156, 241, 0.2);
  border-radius: 2px;
  margin-bottom: 0 !important;
  padding: 4px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 8px;
}

.hidden {
  display: none;
}

.offers-filter-show-more-btn {
  margin-bottom: 0 !important;
}

.offer-filters-container {
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 20px;
}

.custom-offers-datePicker {
  height: 0;
}

.custom-offers-datePicker > .react-date-picker__wrapper {
  border: 0 !important;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__clear-button {
  display: none;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__calendar-button {
  display: none;
}

.custom-offers-datePicker
  > .react-date-picker__wrapper
  > .react-date-picker__inputGroup
  > .react-date-picker__inputGroup__divider {
  display: none;
}

.offers-datepicker-btn {
  border: 1px solid #ebebeb;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  height: 32px;
  font-family: "Raleway", sans-serif;
  border-radius: 4px;
  padding-left: 8px;
  cursor: pointer;
  width: 100%;
  padding-right: 8px;
  text-align: left;
  margin-bottom: 24px;
}

.offers-datepicker-btn img {
  margin-right: 8px;
  height: 16px;
  width: 16px;
}

.offer-filter-collapsable-btn {
  font-size: 16px;
  border: 0;
  background: transparent;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.offer-filters-search {
  padding-left: 32px;
  padding-right: 8px;
  height: 32px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #999999;
  width: 100%;
}

.offer-filters-search-container {
  position: relative;
  margin-bottom: 14px;
  margin-top: 12px;
}

.offer-filters-search-container img {
  position: absolute;
  left: 8px;
  top: 8px;
}

.custom-offers-label {
  font-size: 14px;
  color: #222222;
  font-family: "Raleway", sans-serif;
}

.m-vertical-7 {
  margin: 7px 0;
}

.offers-filter-show-more-btn {
  color: #109cf1;
  font-size: 12px;
  font-weight: 600;
  border: 0;
  background: transparent;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
}

.offers-filter-show-more-btn img {
  margin-left: 8px;
  height: 16px;
  width: 16px;
}

.single-offer-filter {
  margin-bottom: 24px;
}

.custom-card {
  box-shadow: none;
  padding: 20px !important;
}

.ml-20 {
  margin-left: 20px;
}

.offer {
  margin-bottom: 0;
}

.homeoffer-details h5 {
  margin: 0;
}

.homeoffer-details ul li p {
  margin-bottom: 0;
}

.homeoffer-details ul li {
  margin: 11px 0;
}

.price-tag {
  margin-bottom: 0 !important;
}

.applied-filters-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.single-applied-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #109cf1;
  border-radius: 4px;
  background-color: white;
  padding: 3px 5px 3px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
  margin-right: 10px;
}

.single-applied-filter p {
  font-size: 12px !important;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  margin: 0;
  color: #109cf1;
}

.single-applied-filter img {
  cursor: pointer;
  margin-left: 8px;
}

.new-offers-green-btn {
  height: 40px;
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}

.homeoffer-details ul li p,
.homeoffer-details ul li p span {
  font-size: 14px !important;
}

.new-offers-mobile-status-view {
  display: none;
}

.clear-all-offer-filters-btn {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 40px;
  background-color: transparent;
  width: 100%;
  color: #109cf1;
  font-family: "Raleway", sans-serif;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 14px;
  margin-bottom: 0;
  cursor: pointer;
}

.offer-price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.offers-filters-modal-btn {
  display: none;
}

.no-results {
  font-size: 14px !important;
}

.noOffers {
margin: 50px 0;
  text-align: center;
  height: 100vh;
}

.DateRangePicker {
  background: white;
  z-index: 1;
  border: 1px solid #b7b6b6;
  position: absolute !important;
  left: 22px;
  top: 50px;
}

.current-offer-stat-cards {
  margin: 16px 0;
}

.current-offer-trends h5 {
  font-size: 17px;
}

.current-offer-trends p {
  padding: 2px 4px;
  font-size: 9px !important;
}

.current-offer-trends-card > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.current-offer-trends {
  margin-bottom: 0 !important;
}

.current-offer-trends-card > div > img {
  width: 12px;
  margin-right: 4px;
}

.current-offer-trends-card > div > p {
  margin-bottom: 0;
}

.other-agent-offers-btn {
  border-radius: 4px;
  margin-bottom: 0 !important;
}

.other-agent-all-offers-table {
  max-height: 580px;
  overflow-y: scroll;
  overscroll-behavior: none;
}

/* .other-agent-all-offers > table > tbody {
  display: block;
  max-height: 580px;
  overflow-y: scroll;
}

.other-agent-all-offers > table > thead {
  display: table;
  width: calc(100%); 
} */

.other-agent-all-offers th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.other-agent-all-offers-table::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.other-agent-all-offers-table::-webkit-scrollbar-track {
  border-radius: 5px;
}

.other-agent-all-offers-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.other-agent-all-offers-table::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.current-offer-trends-card {
  height: 78px;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.concent {
  margin-bottom: 0;
  padding-bottom: 16px;
}

.share-offers-modal-container {
  max-height: 160px !important;
  overflow-y: scroll !important;
}

.share-offers-modal-container::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-radius: 16px !important;
}

.share-offers-modal-container::-webkit-scrollbar-track {
  border-radius: 5px;
}

.share-offers-modal-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}

.share-offers-modal-container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.ai-icon {
  width: 20px;
}

.homematchx-ai-button {
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  color: #fff;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  font-size: 12px;
  overflow: hidden;
  font-weight: 600;
  border: 1px solid #a445ff;
}

.homematchx-ai-button:hover {
  background: linear-gradient(90deg, #a445ff, #6b00f5);
  box-shadow: 0 0 20px rgba(164, 69, 255, 0.8), 0 0 30px rgba(107, 0, 245, 0.6);
}

.ai-modal-container {
  padding: 20px 0;
}

.gradient-heading {
  font-size: 2.5rem;
  font-weight: bolder;
  background: linear-gradient(90deg, #6b00f5, #a445ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Raleway", sans-serif;
}

.ai-modal-footer {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.ai-modal-footer button {
  margin-bottom: 0;
}

.ai-modal-container p {
  font-size: 1rem;
  line-height: 1.5;
  color: #414141; 
  margin: 0 auto 30px;
  margin: 20px auto 10px;
}

.ai-modal-close-btn {
  background: transparent;
  color: black;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid grey;
  border-radius: 4px;
  margin-right: 12px;
}

.ai-modal-footer .homematchx-ai-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.star-banner {
  position: absolute;
  width: 175px;
  right: 0;
  top: 64px;
}

.weighted-score-toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.scoring-percentage-container {
  margin-top: 10px;
  text-align: center;
}

.ai-score-percentage {
  font-size: 14px;
  margin-bottom: 0;
  color: #333;
  font-weight: bold;
}

.weighted-score-toggle label {
  margin: 0;
  color: #414141;
  margin-bottom: 5px;
}
/* .ai-score-card-container {
  background-color: #90ee90;
  border: 4px solid #008000;
  color: white;
  border-radius: 23px;
  font-size: 18px;
  font-weight: bold;
  padding: 4px;
  width: fit-content;
  min-width: 46px;
  height: 46px;
  text-align: center;
  position: absolute;
  right: 0;
} */
.arrow-down-flipped,
.arrow-down-unflipped {
  width: 12px;
  margin-left: 6px;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.arrow-down-flipped {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.scoring-table-container {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  font-family: Arial, sans-serif;
}

.scoring-table-header {
  background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
}

.scoring-table-header th {
  border: 1px solid rgba(255, 255, 255, 0.2); /* Subtle border */
  text-align: left;
  padding: 8px;
  font-weight: bold;
  color: #ffffff; /* White text for visibility */
}

.scoring-table-row {
  transition: background-color 0.3s ease;
}

.scoring-table-row-even {
  background-color: rgba(255, 255, 255, 0.05); /* Subtle light row */
}

.scoring-table-row-odd {
  background-color: rgba(255, 255, 255, 0.02); /* Slightly darker alternate row */
}

.scoring-table-row:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Highlight on hover */
}

.scoring-table-category {
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
  padding: 8px;
  color: #ffffff;
}

.scoring-table-score {
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: right;
  padding: 8px;
  color: #ffffff;
}

.scoring-table-total-row {
  background-color: rgba(0, 128, 255, 0.2); /* Slightly bright blue for Total Row */
  font-weight: bold;
}

.scoring-table-total-row td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.view-scoring-details-btn {
  font-family: "Raleway", sans-serif;
  min-height: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  color: #28a745;
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #28a745;
}

.view-scoring-details-btn:hover {
  background-color: transparent;
  color: #28a745;
}

.ai-score-card-container {
  background: linear-gradient(90deg, #6b00f5, #a445ff); 
  color: white;
  box-shadow: 0 0 0 3px rgba(164, 69, 255, 1); 
  border: 3px solid transparent;
  background-clip: padding-box;
  border-radius: 23px;
  font-size: 16px;
  padding: 0 4px;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 46px;
  height: 46px;
  text-align: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
}

.ai-score-card-container .tooltip { 
  bottom: 0 !important;
}

.scoring-tooltip-disclamer {
  margin-top: 10px;
  font-size: 12px;
}

.ai-score-tooltip {
  background-color: #222222;
  color: #fff;
  border: none;
  box-shadow: "0px 4px 6px rgba(0, 0, 0, 0.3)";
  font-size: 14px;
  max-width: 400px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}

.ai-score-tooltip p, .ai-score-tooltip ul, .ai-score-tooltip ul li, .ai-score-tooltip strong {
  text-align: left;
  color: #979797;
}

.ai-score-tooltip strong {
  font-weight: 500;
}

.ai-score-tooltip .sb-point {
  margin-bottom: 10px;
}

.ai-score-tooltip code {
  font-size: 14px;
}

.tooltip-heading {
  color: white !important;
  font-size: 20px;
  text-align: center !important;
  font-weight: bold;
  margin-bottom: 10px;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip-subheading {
  color: #f8d775 !important;
  margin-bottom: 6px;
}

.ai-score-tooltip .example-calculation {
  background-color: #444;
  padding: 6px;
  border-radius: 4px;
  margin-top: 5px;
  word-wrap: break-word;
  font-family: monospace;
  white-space: pre-line;
  overflow-wrap: break-word;
  text-align: left;
  word-break: break-word
}

.learn-scoring-button {
  background: transparent;
  border: 0;
  outline: none;
  text-align: left;
}

.learn-scoring-button:hover {
  text-decoration: underline;
  background: transparent;
}

.learn-scoring-modal .modal-header {
  border: none;
}

.learn-scoring-modal .modal-body {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 20px;
}

.learn-scoring-modal h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.tooltip-subheading {
  color: #c99600 !important;
  font-size: 18px;
  margin-top: 20px;
}

.weighted-list,
.detailed-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.detailed-list ul {
  padding-left: 20px;
}

.example-calculation {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.calc-cancel-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.calc-cancel-btn:hover {
  background-color: #5a6268;
}

@media (max-width: 1235px) {
  .offer-price-container {
    flex-direction: column;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .new-offers-green-btn {
    margin-top: 16px;
    margin-bottom: 0;
  }
  .current-offer-trends-card {
    height: 110px;
  }
}

@media (max-width: 1200px) {
  .current-offer-trends-card {
    margin-bottom: 12px;
  }
}

@media (max-width: 1160px) {
  .new-offers-mobile-status-view {
    display: block;
  }
  .new-offers-status-view {
    display: none;
  }
}

@media (max-width: 1128px) {
  .offers-stats-card {
    padding: 0 20px 0;
    display: flex;
    height: 85px;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .homeoffer-details {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .house img {
    height: 202px;
    object-fit: contain;
  }
  .offer-title-section {
    flex-direction: column;
  }
  .offer-title-section div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .offer-title-section > div > .follow-my-listing-btn {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .offer-title-section > div > .create-spreadsheet-btn {
    margin-left: 0;
    margin-bottom: 0;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .offer-title-section > div > .create-spreadsheet-btn > img {
    margin-right: 10px;
  }
  .offers-stats-card-container {
    padding: 5px !important;
  }
  .offers-filters-modal-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: white;
    color: #109cf1;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid #ebebeb;
    height: 40px;
    border-radius: 4px;
    font-family: "Raleway", sans-serif;
    align-self: center;
    width: 100%;
  }
  .offers-filters-modal-btn img {
    margin-right: 8px;
  }
  .offers-desktop-filters {
    display: none;
  }
  .offers-new-mobile-list {
    padding-right: 15px !important;
  }
  .mobile-filters-modal {
    margin: 0;
  }
  .mobile-filters-modal .modal-content {
    border: 0;
    border-radius: 0;
  }
  .mobile-filters-modal .modal-content .modal-body {
    height: 100vh;
    overflow: hidden;
  }
  .mobile-filters-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eff0f0;
  }
  .mobile-filters-modal-header h6 {
    font-size: 22px;
    color: #222222;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    margin-bottom: 0;
  }
  .mobile-filters-modal-header img {
    cursor: pointer;
  }
  .mobile-filters-modal-footer {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    background-color: white;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .filter-modal-apply-btn {
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    background-color: #109cf1;
    color: white;
    height: 40px;
    border-radius: 4px;
    border: 0;
    margin-bottom: 0;
    cursor: pointer;
    width: calc(100% - 101px);
  }
  .filter-modal-cancel-btn {
    color: #109cf1;
    font-size: 16px;
    font-weight: 600;
    font-family: "Raleway", sans-serif;
    height: 40px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #ebebeb;
    margin-bottom: 0;
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
  }
  .modal-body-filters .custom-control .custom-offers-label {
    margin: 0;
  }
  .modal-body-filters div .offers-filter-show-more-btn {
    margin-bottom: 0;
  }
  .modal-offer-filters {
    overflow: scroll;
    height: calc(100% - 130px);
  }
  .offers-cards {
    margin: 20px 0;
  }
  .offer-title-section {
    align-items: flex-start;
  }
  .offer-title-section h2 {
    margin-bottom: 20px;
  }
  .offers-new-mobile-list {
    padding: 0 3px 0 3px !important;
  }
  .DateRangePicker {
    background: white;
    z-index: 1;
    border: 1px solid #b7b6b6;
    position: relative !important;
    left: 0;
    top: 0;
  }
  .dashboard-content h3 {
    padding: 0 5px;
  }
  .ai-score-card-container {
    font-size: 14px;
    top: 4px;
  }
}

.seller-main-heading {
  font-family: "Raleway", sans-serif;
  font-size: 22px !important;
  font-weight: 600 !important;
  color: #222222;
  padding-top: 10px;
}

.dashboard-content {
  background: #f6f7fa;
}

.dashboard-list-single {
  padding: 0;
  box-shadow: none;
}

.sellers-bg-transparent {
  background-color: transparent !important;
}
.seller-list-item-main {
  background: white;
  margin-bottom: 30px;
  padding: 30px;
  box-shadow: 0 0 35px rgb(0 0 0 / 10%);
}

.verbal-offer-term-check label {
  padding-left: 0 !important;
}

.align-item-center {
  align-items: center;
}

.search-box {
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  color: #999;
  width: 230px;
}

.back-btn {
  padding: 0 30px;
  background-color: transparent;
  border: 1px solid #222222;
  font-size: 18px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-bottom: 8px;
}

.share-btn {
  padding: 0 30px;
  width: 185px;
  background-color: transparent;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: #28a745;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  margin-right: 24px;
}

.share-btn:hover {
  background-color: #28a745;
  color: white;
}

.create-seller-btn {
  padding: 8px 30px;
  width: 185px;
  text-transform: none !important;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 18px !important;
  color: white;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
}

.create-seller-btn:hover {
  background-color: white;
  border: 1px solid #28a745;
  color: #28a745;
}

.sellers-name {
  color: #222222;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.p-0 {
  padding: 0;
}

.pin-icon {
  color: #999999;
  font-size: 13px;
}

.address {
  color: #222222;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}

.draft-p {
  margin-bottom: 10px;
}

.draft-badge {
  background: #0376fc;
  font-size: 12px;
  padding: 3px 8px;
}

.capitalize {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 28px;
  color: #222222;
}

.details-col {
  font-size: 16px !important;
  color: #222222;
}

td.def {
  padding-right: 70px !important;
}

.see-more {
  width: 130px;
  text-transform: none !important;
  background-color: #28a745;
  border: 1px solid #28a745;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: white;
  border-radius: 5px;
  height: 30px;
  cursor: pointer;
  margin-top: 10px;
}

.custom-arrow {
  font-size: 12px;
  padding-right: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.action {
  padding-left: 0;
  margin-bottom: 0 !important;
}

.custom-fa-icon {
  height: 15px;
  width: 15px;
  background: #999999;
  text-align: center;
  border-radius: 16px;
  color: white;
  font-size: 10px;
  padding-top: 3px;
  margin-right: 10px !important;
}

.details-txt {
  font-size: 14px !important;
  color: #222222 !important;
  font-weight: 500 !important;
  padding-right: 20px;
}

.profile-notify {
  font-size: 14px !important;
  margin-top: 80px;
  margin-bottom: 0;
  color: #999999;
  font-weight: 500;
}

.price-tag {
  color: #eb3e54 !important;
  font-weight: bold !important;
  font-size: 22px !important;
  margin-bottom: 30px;
}

.custom-card {
  border: 0;
  padding: 30px;
  margin-bottom: 30px;
}

.best-match-offers-card {
  border: 3px solid #f8c007;
  /* padding: 30px; */
  margin-bottom: 30px;
}

.offers-thumbnail-view-padding {
  padding: 10px 30px 30px 30px;
}

.ml--1 {
  margin-left: -1px;
}

.transparent-btn {
  padding: 0 9px;
  text-transform: none !important;
  background-color: white;
  font-size: 16px !important;
  color: #222222;
  border-radius: 5px;
  height: 44px;
  cursor: pointer;
  border: 1px solid #dcdcdd;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 10px !important;
}

.pl-30 {
  padding-left: 30px;
}

.borderless-btn {
  text-transform: none !important;
  background-color: transparent;
  font-size: 16px !important;
  color: #222222;
  height: 44px;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 20px;
  border: 0;
  color: #28a745;
}

.pr-10 {
  padding-right: 10px;
}

.underline {
  text-decoration: underline;
  color: #222222;
  margin-right: 10px;
}

.maps-modal-body {
  padding: 1rem;
  margin: 0;
}

.sellers-status-filter {
  border-radius: 5px;
  background: transparent;
  margin-right: 8px !important;
  margin-bottom: 8px;
  border: 1px solid black;
  cursor: pointer;
  padding: 10px 0 !important;
  padding-left: 5px !important;
  min-height: 40px !important;
  margin-top: 1px;
}

.margin-top-none {
  margin-top: 0 !important;
}

.status-change-btn {
  text-decoration: none !important;
  color: #222222 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 0;
  margin-bottom: 4px;
}

.status-change-btn:hover {
  color: #222222 !important;
}

.change-status-icon {
  margin-right: 6px;
  background-color: #999999;
  color: white;
  font-size: 10px;
  border-radius: 16px;
  padding: 3px 2.5px 2px 2.4px;
}

@media (max-width: 1210px) {
  .mobile-responsive-share-btn {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 480px) {
  .mobile-responsive-share-btn {
    margin-bottom: 20px !important;
  }
  .back-btn {
    margin-right: 0;
  }
  .share-btn {
    margin-right: 0;
  }
  .centerly {
    text-align: center !important;
  }
  .mobile-responsive {
    width: 100%;
  }
  .manage-table .action {
    text-align: center;
  }
  .mobile-btn-view-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .sellers-status-filter {
    margin-bottom: 0;
  }
  .mobile-responsive-back-btn {
    margin-bottom: 22px !important;
  }
  .seller-filters-mob-view {
    align-items: flex-start !important;
  }
  .mob-no-label {
    display: none;
  }
  .search-box {
    width: 100%;
  }
  .update-sellers-mob-view {
    margin-left: 0 !important;
  }
  .mob-status-filter {
    margin-top: 20px;
  }
}

@media (max-width: 415px) {
  .mobile-responsive-share-btn {
    margin-top: 19px !important;
    margin-left: 8px;
  }
  .mobile-responsive-back-btn {
    margin-bottom: 0 !important ;
  }
}

