.analytics-main {
  background-color: #f6f7fa;
  width: 100%;
  min-height: 91.1vh;
}

.highcharts-axis text {
  display: none;
}

.highcharts-no-tooltip {
  display: none;
}

.ml-15 {
  margin-left: 15px;
}

.dropdown-menu.show {
  padding: 8px;
}
.drop {
  max-height: 200px;
  overflow-y: scroll;
}
.mob-dropdown-brokerage {
  transform: translate3d(0px, 32px, 0px) !important;
}

.propertyFilterToggle img {
  height: 22px;
  width: 22px;
  margin: 0 0 2px 5px;
}

.propertyFilterChkBox {
  margin-right: 4px;
}

.propertyFilterToggle {
  background: transparent;
  border: none;
  margin-bottom: 5px;
  margin-right: 15px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  cursor: pointer;
}

.custom-dropdownMenu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
}

.z-index {
  z-index: 10000 !important;
}

.custom-dropdownMenu-search {
  z-index: 1000;
  position: absolute;
  background-color: white;
  box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -webkit-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 7px 14px 0px rgba(0, 0, 0, 0.28);
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
  height: 315px !important;
  overflow: scroll;
}

.charts-main-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 20px;
  margin-left: 0;
  /* border: 2px solid white; */
}

.average-offers-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
  margin-right: 0 !important;
}

.maps-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  margin-bottom: 30px;
}

.charts {
  color: red;
}

.charts-main-container:hover {
  /* border: 2px solid #109cf1; */
  cursor: pointer;
}

.property-engagement-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.chart-title {
  color: #222222;
  font-size: 14px !important;
  font-weight: 600;
}

.font-18-title {
  color: #222222;
  font-size: 18px !important;
  font-weight: 600;
  margin-bottom: 25px;
}

.chart-title-btn {
  color: #222222;
  font-size: 14px !important;
  font-weight: 400;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-bottom: 1px solid white;
}

.chart-title-btn:hover {
  border-bottom: 1px solid #000000;
}

.property-engagement-tools select {
  font-size: 14px !important;
  border: 0;
  color: #222222;
  width: 140px;
  cursor: pointer;
}

.property-engagement-chart {
  width: 100%;
}

.highcharts-scrolling::-webkit-scrollbar {
  height: 8px;
}
.highcharts-scrolling::-webkit-scrollbar-track {
  background: #efefef;
  border-radius: 2px;
}

.highcharts-scrolling::-webkit-scrollbar-thumb {
  background: #109cf1;
  border-radius: 2px;
}

.chart-mr-left {
  margin: 0 15px;
}

.green-dot {
  margin-top: 8px;
  height: 9px;
  width: 9px;
  border-radius: 9px;
  background-color: #28a745;
}

.red-dot {
  margin-top: 8px;
  height: 9px;
  width: 9px;
  border-radius: 9px;
  background-color: #eb3e54;
}

.analytics-centered-row {
  display: flex;
  flex-direction: row;
}

.offer-days {
  color: #999999;
  font-size: 14px !important;
  font-weight: 500;
  margin: 0;
}

.up-arrow {
  height: 14px;
  margin-right: 5px;
}

.green-percentage-value {
  font-size: 14px !important;
  font-weight: 500;
  color: #28a745;
  margin: 0;
}

.red-percentage-value {
  font-size: 14px !important;
  font-weight: 500;
  color: #eb3e54;
  margin: 0;
}

.ml-8 {
  margin-left: 8px;
}

.gm-bundled-control-on-bottom {
  bottom: 80px !important;
}

.analytics-total-offers {
  margin: 0;
  color: #000000;
  font-size: 18px !important;
  margin-right: 10px;
}

.center-me {
  align-items: center;
}

.flex-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.analytics-btn-group {
  background-color: white;
  border-radius: 5;
  border: 0;
  margin-bottom: 10px;
}

.analytics-btn-group-btns {
  background-color: #e9ecef;
  border-radius: 5;
  border: 0;
  color: #222222;
  font-size: 14px !important;
  margin-top: 0;
  height: 25px;
  padding: 0 19px;
}

.analytics-agent-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  padding: 20px;
  height: 180px;
  margin: 0 15px 30px;
  border: 2px solid white;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.analytics-agent-container:hover {
  border: 2px solid #109cf1;
  cursor: pointer;
}

.agent-tag {
  font-size: 10px !important;
  color: #222222;
  margin: 0;
  margin-top: 10px;
}

.agent-name {
  font-family: 16px !important;
  color: #000000;
  font-weight: 500;
  margin: 0;
}

.analytics-agent-img {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}

.donutChart {
  height: 120px;
  width: 226px;
  overflow: hidden;
}

.analytics-row {
  display: flex;
  flex-direction: row;
}

.orange-circle {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  border: 3px solid #ffc107;
  margin-right: 6px;
}

.green-circle {
  height: 8px;
  width: 8px;
  border-radius: 8px;
  border: 3px solid #28a745;
  margin-right: 6px;
}

.blue-circle {
  height: 10px;
  width: 10px;
  border-radius: 8px;
  border: 3px solid #109cf1;
  margin-right: 6px;
}

.price-range-text {
  font-size: 14px !important;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.home-other-accessory {
  font-size: 10px !important;
  font-weight: 500;
  color: #999999;
  margin: 0;
  margin-top: 10px;
}

.donut-chart-heading {
  margin-bottom: 10px;
}

.circle-shaped {
  font-weight: 600;
  background-color: #109cf1;
  border-radius: 50%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  height: 100px;
  width: 100px;
  min-width: 100px;
  border-radius: 100px;
}

.circle-shaped-text {
  font-size: 40px !important;
  color: white;
  margin-bottom: 10px;
  font-weight: 600;
}

.price-difference {
  font-size: 14px !important;
  font-weight: 400;
  color: #000000;
}

.mt-10 {
  margin-top: 10px;
}

.donut-chart-value {
  color: #000000;
  font-size: 24px !important;
  font-weight: 600;
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pl-0 {
  padding-left: 0;
}

.mr-15 {
  margin-right: 15px;
}

.map-filters select {
  font-size: 14px !important;
  font-weight: 500;
  border: 0;
  cursor: pointer;
  margin: 4px 0;
  padding: 3px 0;
  width: 100%;
}

.states-select {
  width: 66px;
}

.zip-select {
  width: 46px;
}

.analytics-input-label {
  font-size: 12px !important;
  font-weight: 500;
  border: 0;
  color: #999999;
  cursor: pointer;
  margin: 5px 0;
  padding: 5px 0;
  white-space: nowrap;
}

.analytics-property-address-label {
  font-size: 14px !important;
  border: 0;
  color: #222222;
  cursor: pointer;
  margin: 5px 0;
  /* padding: 5px 0; */
}

.total-offers-by-listings-margin {
  margin: 0 0 30px 0;
}

.average-offers-per-listing-container {
  background-color: #109cf1;
  height: 381px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.padding-20 {
  padding: 25px 20px;
}

.analytics-table-sides {
  padding: 20px 5px;
}

.average-offers-text {
  color: white;
  font-size: 14px !important;
  font-weight: 600;
  padding: 0px 15px;
  text-align: center;
}

.average-offers {
  font-size: 100px !important;
  font-weight: 600;
  color: white;
  margin: 0;
}

.analytics-price-input {
  width: 120px;
  padding: 6px 10px;
  color: #999999;
  font-size: 14px !important;
  border: 1px solid #e1dfdf;
  margin: 4px 0;
}

.price-inputs {
  width: 70px;
  padding: 4px 8px;
  color: #999999;
  font-size: 12px !important;
  border: 1px solid #e1dfdf;
}

.analytics-search-input {
  border-radius: 2px;
  padding: 8px 8px;
  color: #999999;
  font-size: 14px !important;
  border: 1px solid #e1dfdf;
  width: 100%;
}

.avg-offers-terms {
  padding: 20px 0 25px;
  margin: 0;
}

.price-input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.maps-filters-container {
  margin-top: 60px;
  border-left: 1px solid #d0d0d0;
  padding-left: 20px;
}

.analytics-table-td {
  font-size: 14px !important;
  font-weight: 500;
  height: auto !important;
}

.green-box {
  height: 14px;
  width: 14px;
  background-color: #28a745;
  margin-right: 10px;
}

.blue-box {
  background-color: #129de0;
  height: 14px;
  width: 14px;
  margin-right: 10px;
}

.box-label {
  font-size: 12px !important;
  font-weight: 400;
  color: #000000;
  margin: 0;
}

.ml-20 {
  margin-left: 20px;
}

.total-received-offers-container {
  background-color: #109cf1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.total-avg-offers {
  font-size: 100px !important;
  color: white;
  font-weight: 600;
  margin-bottom: 0;
}

.average-terms-container {
  padding: 20px;
  padding-left: 5px;
}

.custom-spaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: url("../icons/gallery/line.png");
  background-position: center bottom;
  background-repeat: repeat-x;
  margin: 10px 0;
}

.analytics-text-normal {
  font-size: 14px !important;
  line-height: 10px;
  color: #000000;
  font-weight: 500;
  background-color: white;
  margin: 0;
}

.margin-avg-head {
  margin-bottom: 60px;
}

.analytics-lined-td {
  font-size: 16px !important;
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid black;
  margin: 0;
  display: initial;
  cursor: pointer;
}

.analytics-find-buyer-table td {
  font-weight: 500;
  color: #000000;
  border-bottom: 1px solid black;
}

.other-agents-filter {
  font-size: 14px !important;
  background-color: white !important;
  color: #28a745 !important;
  border: 0 !important;
  border-bottom: 1px dashed #28a745 !important;
  padding: 0;
  cursor: pointer;
  line-height: 15px;
  font-weight: 500;
}

.analytics-offer-details-btn {
  background-color: white;
  color: #28a745;
  border: 0;
  border-bottom: 1px solid #28a745;
  font-size: 14px !important;
  padding: 0;
  cursor: pointer;
  line-height: 15px;
}

.analytics-offer-details-btn:hover,
.analytics-offer-details-btn:focus,
.analytics-offer-details-btn:active,
.analytics-offer-details-btn:visited,
.analytics-offer-details-btn:focus-visible,
.analytics-offer-details-btn:focus-within {
  background-color: white;
  color: #28a745;
}

.address-content-width {
  width: 200px;
  padding: 10px 16px !important;
}

.table-normal-text {
  font-size: 16px !important;
  color: #000000;
  font-weight: 500;
  margin: 0;
}
.all-offers {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
.find-buyer-agents-filters {
  background-color: white;
  padding: 20px;
  box-shadow: 0px 6px 18px rgb(0 0 0 / 6%);
  border-radius: 4px;
}

.find-buyer-agents-filters select {
  border: 1px solid;
  border-radius: 4px;
  margin-bottom: 5px;
  margin-right: 15px;
  padding: 5px;
}

.width-60 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.analytics-offer-days {
  margin-bottom: 10px;
}

.small-font-text {
  font-size: 12px !important;
  margin: 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.white-border {
  border-top: 8px solid white !important;
}

.custom-price-options-list {
  width: 180px;
  /* border: 1px solid black; */
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-address-options-list {
  width: 350px;
  position: absolute;
  background: white;
  z-index: 3;
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-price-options-list::-webkit-scrollbar {
  width: 4px;
}

.custom-price-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.custom-price-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.custom-users-filter-list {
  width: 200px;
  position: absolute;
  background: white;
  z-index: 3;
  overflow-x: hidden;
  max-height: 410px;
  overflow-y: scroll;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.custom-users-filter-list::-webkit-scrollbar {
  width: 4px;
}

.custom-users-filter-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.custom-users-filter-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.down-arrow {
  margin-left: 10px;
  width: 10px;
}

.font-15 {
  font-size: 15px !important;
}

.price-options-list-p:hover p {
  color: white;
}

.analytics-price-dropdown-btn {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
}

.analytics-price-dropdown-inner-btn {
  border: 0;
  background: transparent;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 8px;
}

.analytics-price-dropdown-inner-btn p {
  color: #999999;
  font-size: 14px !important;
  margin: 0;
}

.analytics-price-dropdown-inner-btn:hover,
.analytics-price-dropdown-inner-btn:hover p {
  background-color: #1e90ff;
  color: white !important;
}

.analtics-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.padd-6 {
  padding: 6px;
}

.margin-0 {
  margin: 0 !important;
}

.extra-padd-left {
  padding-left: 38px;
}

.analytics-price-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aeaeae;
  opacity: 1; /* Firefox */
}

.analytics-price-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aeaeae;
}

.analytics-price-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aeaeae;
}

.analytics-agent-img {
  height: 100px;
  width: 100px;
  border-radius: 100px;
}

.analytics-agent-details {
  margin-top: 30px;
}

.analytics-agent-details a,
.analytics-agent-details p {
  font-size: 14px !important;
  margin: 0;
}

.analytics-agent-details i {
  margin-right: 8px;
}

.agent-about {
  font-size: 20px !important;
  font-weight: 600;
  color: #000000;
  margin-top: 20px !important;
}

.mVertical-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.font-40 {
  font-size: 40px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.phone-icon {
  margin-right: 12px !important;
}

.list-arrow {
  width: 11px;
  margin-left: 6px;
}

.analytics-map-large-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 0;
}

.large-filter-options-list {
  width: 196px;
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.large-filter-options-list::-webkit-scrollbar {
  width: 4px;
}

.large-filter-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.large-filter-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.analytics-map-nrml-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  max-width: 100%;
  width: 185px;
  margin-bottom: 0;
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div > div {
  max-height: 1px !important;
  margin: 0 !important;
  width: 100% !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div:first-of-type {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 62px !important;
  border-radius: 4px !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div
  button:last-of-type {
  background: #ffffff !important;
  border-radius: 0px 0px 4px 4px !important;
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div button img {
  width: 9px !important;
  max-height: 9px !important;
  filter: brightness(0) saturate(100%) invert(62%) sepia(25%) saturate(1%)
    hue-rotate(314deg) brightness(94%) contrast(89%);
}

.analytics-google-map .gm-bundled-control-on-bottom .gmnoprint div button {
  width: 32px !important;
}

.analytics-google-map
  .gm-bundled-control-on-bottom
  .gmnoprint
  div:first-of-type {
  width: 32px !important;
}

.analytics-map-rad-filter,
.analytics-map-pri-filter {
  border: 1px solid #ebebeb;
  border-radius: 6px;
  background: transparent;
  height: 32px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  margin-bottom: 0;
}

.nrml-filter-options-list {
  width: 110px;
  position: absolute;
  background: white;
  z-index: 2;
  max-height: 200px;
  overflow-y: scroll;
  margin-left: -1px;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}

.nrml-filter-options-list::-webkit-scrollbar {
  width: 4px;
}

.nrml-filter-options-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
}

.nrml-filter-options-list::-webkit-scrollbar-thumb {
  background-color: #28a745;
  border-radius: 10px;
}

.flex-row {
  display: flex;
}

.offer-map-location-block {
  justify-content: space-between;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.total-offer-link-btn {
  color: #000000;
  font-size: 13px !important;
  font-weight: 700;
}

.map-location-input {
  border: 1px solid #cccccc;
  border-radius: 6px;
  background: transparent;
  width: 196px;
  height: 36px;
  font-size: 14px !important;
  padding-left: 35px;
}

.search-placeholder {
  height: 18px;
  width: 18px;
  position: absolute;
  top: 8px;
  left: 12px;
}

.pin-button {
  position: absolute;
  top: 3px;
  right: 6px;
  cursor: pointer;
  background: transparent;
  border: none;
}

.pin-placeholder {
  height: 19px;
  width: 16px;
}

.fullscreen-btn {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  height: 36px;
  width: 36px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.row-space-btwn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full-screen-icon {
  width: 15px;
}

.analytics-google-map {
  width: 100%;
  height: 600px;
}

.total-offers-received-notifier {
  border: 1px solid #edeef0;
  padding: 15px 20px;
}

.total-offers-received-notifier div {
  background-color: #109cf1;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.total-offers-received-notifier img {
  height: 18px;
  width: 18px;
}

.total-offers-received-notifier p {
  color: white;
  margin: 0;
  margin-left: 3px;
}

.properties-container {
  padding: 15px 20px 0 20px;
  height: 535px;
  max-height: 535px;
  border-bottom: 1px solid #edeef0;
  overflow-y: scroll;
}

.properties-container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.analytics-single-property {
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 4px;
  border: 1px solid #edeef0;
  margin-bottom: 18px;
  max-width: 300px;
}

.analytics-property-img {
  height: 160px;
  width: 100%;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.property-purchase-price {
  font-size: 26px !important;
  color: #999999;
  font-weight: 700;
  margin-bottom: 0px;
}

.total-offers-on-property {
  background-color: #ffc107;
  border-radius: 16px;
  display: flex;
  height: 27px;
  flex-direction: row;
  padding: 4px 10px;
  align-items: center;
  width: fit-content;
}

.total-offers-on-property p {
  font-size: 13px !important;
  font-weight: bold;
  color: black;
  margin: 0;
}

.total-offers-on-property img {
  height: 18px;
  width: 18px;
  margin-right: 6px;
}

.total-offers-on-property p {
  color: #000000;
  font-weight: bold;
}

.analytics-property-inner-container {
  padding: 18px 10px;
}

.mt-20 {
  margin-top: 20px;
}

.closed {
  background-color: #000000;
  border-radius: 4px;
  height: 21px;
  padding: 1px 20px;
  color: white;
  margin-left: 8px;
  margin-bottom: 0;
  font-size: 13px !important;
}

.property-txt {
  color: #999999;
  font-weight: 600;
  margin: 0;
}

.wrapped-flex-row {
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  margin: 24px 0 18px;
}

.property-main-features {
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  height: 28px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  margin-right: 5px;
  margin-bottom: 6px;
  width: fit-content;
}

.property-main-features img {
  width: 18px;
  height: 17px;
  margin-right: 6px;
}

.property-main-features p {
  color: #000000;
  margin: 0;
  font-size: 13px !important;
  font-weight: 600;
}

.review-property-offers {
  color: #40b15a;
  background-color: transparent;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
}

.review-property-offers-map {
  color: #fff;
  font-weight: bold;
  border-radius: 8px;
  background-color: #40b15a;
  padding: 10px;
  display: block;
  text-align: center;
}

.analytics-single-property:hover .review-property-offers {
  background-color: #28a745;
  border: 1.5px solid #28a745;
  color: white;
}

.analytics-single-property:hover .property-purchase-price {
  color: #ff214f;
}

.analytics-single-property:hover {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.analytics-single-property:hover .analytics-property-img {
  filter: none;
  -webkit-filter: grayscale(0);
}

.arrow-right-button {
  border: 0;
  background: white;
  height: 36px;
  width: 36px;
  position: absolute;
  top: 30%;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
}

.arrow-right-unflipped {
  width: 14px;
  transform: rotate(-90deg);
}

.arrow-right-flipped {
  width: 14px;
  transform: rotate(90deg);
}

.ease-in-n-out {
  transition: 0.75s;
  transition-timing-function: ease-out;
}

.map-draw-btn {
  border: 0;
  background: white;
  height: 40px;
  width: 76px;
  color: #109cf1;
  position: absolute;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
  top: 20px;
  left: 20px;
  font-weight: 600;
}

.draw-map-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 73px;
  background-color: white;
  padding: 0 25px;
  position: absolute;
  z-index: 2;
  top: 0;
  align-items: center;
}

.blue-color-txt {
  color: #109cf1;
  font-weight: 600;
}

.draw-map-cancel-btn {
  height: 34px;
  width: 80px;
  background-color: transparent;
  color: #109cf1;
  border-radius: 4px;
  border: 1px solid #109cf1;
  margin-right: 8px;
  cursor: pointer;
}

.draw-map-apply-btn {
  height: 34px;
  width: 80px;
  background-color: #109cf1;
  color: white;
  border-radius: 4px;
  border: 1px solid #109cf1;
  cursor: pointer;
}

.draw-map-container p {
  margin: 0;
}

.custom-badge-padding {
  margin-left: 8px;
  margin-bottom: 0;
}

.analytics-property-filter {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../icons/arrow.png");
  background-repeat: no-repeat;
  background-position: 82px center;
  padding-right: 20px;
  color: rgba(34, 34, 34, 1);
  width: 90px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.analytics-property-filter option {
  color: rgba(34, 34, 34, 1);
}

.searchIcon {
  width: 18px;
  height: 19px;
}

.locationInput {
  max-height: 32px;
  max-width: 135px;
  width: 100%;
  border: none;
  border-radius: 4px;
  text-indent: 10px;
  font-size: 12px;
}

.locationInput::placeholder {
  color: #222222;
}

.locationImg {
  color: #999999;
  width: 13px;
}

.mapInput {
  border: 1px solid #ebebeb;
  border-radius: 4px;
  max-height: 32px;
  display: flex;
  padding: 0 10px;
  align-items: center;
}
.chartWrapper {
  background-color: #fff;
  width: 100%;
}
.chartWrapper-scroll {
  max-width: 100%;
  overflow-x: scroll;
  margin-right: 20px;
}
.chartWrapper-scroll-global {
  overflow-x: auto;
}
.chartWrapper-scroll::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

.chartWrapper-scroll::-webkit-scrollbar-track {
  margin: 0 10px;
  background: #efefef;
}

.chartWrapper-scroll::-webkit-scrollbar-thumb {
  background-color: #109cf1;
  border-radius: 2px;
}

.offer-status {
  padding: 3px 20px;
  background: #222222;
  border-radius: 6px;
  color: #fff;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  border: none;
}

.property-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #999999;
}

.offers-count-title {
  font-weight: 700;
  font-size: 12px;
}

.calendar,
.withoutValue {
  min-width: 170px;
}

.upper-filters {
  padding-left: 20px;
  padding-right: 20px;
}

.calendar button {
  padding-left: 0;
}

.calendar .react-daterange-picker,
.calendar .react-daterange-picker__clear-button {
  padding: 0;
}

.calendar .react-daterange-picker__clear-button svg {
  width: 14px;
}

.calendar .react-daterange-picker__wrapper {
  border: none;
}
.calendar .react-daterange-picker__inputGroup {
  font-size: 10px;
}

.withoutValue .react-daterange-picker__wrapper {
  border: none;
}

.withoutValue .react-daterange-picker__inputGroup {
  font-size: 10px;
}

.withoutValue .react-daterange-picker__inputGroup input {
  display: none;
}

.withoutValue .react-daterange-picker__inputGroup span {
  display: none;
}

.withoutValue .react-daterange-picker__range-divider {
  display: none;
}

.scroll-cut {
  width: 29px;
  height: 13px;
  background: #fff;
  position: absolute;
  bottom: 19px;
  left: 30px;
}
.scroll-cut::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background: #fff;
  right: -0.8px;
  transform: rotate(45deg);
}

.scroll-cut::after {
  content: "";
  position: absolute;
  width: 3px;
  height: 3px;
  background: #fff;
  right: -0.8px;
  bottom: -1.7px;
  transform: rotate(45deg);
}

.total-over-time-chart {
  width: 545px;
}

.react-select-container {
  height: 32px !important;
}

.react-select-groups > .css-bg1rzq-control {
  height: auto !important;
}

.css-bg1rzq-control {
  height: 32px !important;
  min-height: 0px !important;
  border-color: #ebebeb !important;
  border: 1px solid #ebebeb !important;
  border-radius: 6px !important;
}

.css-1wy0on6 {
  height: 32px !important;
}

.line-chart-width {
  max-width: 380px;
}

.summary-detail-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.all-client-offers-badge-width {
  width: auto !important;
}

.thankme-modal-header {
  border-bottom: 1px solid #f8f9fa !important;
  padding-top: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.thankme-modal-header p {
  padding: 0 10px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #000000;
}

.thankme-modal-header button {
  padding: 0 15px !important;
  margin: 0 !important;
}

.thank-me-buttons {
  background-color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #e9ecef;
  color: #109cf1;
}
.thank-you-btn {
  background-color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #e9ecef;
  color: #109cf1;
  height: 40px !important;
}

.thank-me-buttons-active {
  background-color: #109cf1;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
}

.thankme-price-btn {
  width: 20%;
}

.thankme-textarea {
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.thank-me-continue {
  background-color: #109cf1;
  color: white;
  font-weight: 600;
  border-radius: 6px;
  border: none;
  font-size: 12px;
  height: 40px !important;
}

.modal-content {
  border-radius: 6px;
}

.trend-text {
  font-size: 14px;
  color: #ff214f !important;
  text-decoration: underline;
}

.global-chart-trend-img {
  vertical-align: baseline !important;
  height: 12px !important;
  margin-right: 5px !important;
}

.view-trend-offers-btn {
  margin-bottom: 0px !important;
}

.tooltip {
  position: absolute;
  /* background-color: #333; */
  background-color: transparent;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  opacity: 0.8;
}

.info-icon-tooltip-container {
  position: relative;
  display: inline-block;
}

.info-icon {
  cursor: pointer;
  font-weight: bold;
}

.tooltip-content {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px;
  width: 200px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
}

.tooltip-content-property-page {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #000000;
  padding: 5px;
  width: 200px;
  text-align: center;
  font-size: 14px;
  z-index: 1;
}

.my-analytics-container {
  background-color: #fff !important;
}

@media (max-width: 1440px) {
  .tab-responsive-flex-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .draw-map-container {
    height: 130px;
    padding: 0 10px;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
}

@media (max-width: 1250px) {
  .average-offers {
    font-size: 40px !important;
  }
}

@media (max-width: 1166px) {
  .total-received-offers-container {
    height: 357px;
  }
  .analytics-offer-details-btn {
    margin: 0;
    border-bottom: 0;
  }
  .total-offers-on-property {
    margin-bottom: 8px;
  }
  .custom-badge-padding {
    margin-bottom: 8px;
  }
  .closed {
    margin-bottom: 8px;
  }
}

@media (max-width: 1040px) {
  .analytics-google-map {
    position: relative !important;
  }
  .max-width-400 {
    max-width: 400px;
    margin: auto;
  }
  .total-offers-received-notifier {
    border: 0;
    margin-top: 15px;
  }
}

/* media max and min */
@media (max-width: 1026px) and (min-width: 576px) {
  .responsive-height {
    height: 107px !important;
  }

  .charts-main-container {
    height: 100% !important;
  }

  /* .global-chart-button-margin-bottom {
    margin-bottom: 0px !important;
  } */

  .global-chart-text-padding {
    padding: 0px !important;
  }

  .summary-detail-container {
    justify-content: flex-start;
  }
  .Analytic_exportButton__Exzyl {
    margin-bottom: 0px !important;
  }
  .Analytic_viewOffers__185Xo {
    margin-bottom: 0px !important;
  }

  .total-over-time-chart {
    width: 200px !important;
  }
  /* .line-chart-width {
  max-width: 100%;
} */
}

@media (max-width: 992px) {
  /* .global-chart-button-margin-bottom {
    margin-bottom: 0px !important;
  } */

  .total-over-time-chart {
    width: -webkit-fill-available;
  }

  .line-chart-width {
    max-width: 100%;
  }

  .tab-responsive-mr-15 {
    margin-right: 15px;
  }
  .tab-responsive-mb-30 {
    margin-bottom: 30px;
  }
  .analytics-table-left {
    padding: 20px !important;
  }
  .analytics-table-right {
    padding: 20px !important;
  }
  .maps-filters-container {
    border: 0;
  }
  .draw-map-container {
    left: 0 !important;
  }
}

@media (max-width: 576px) {
  .analytics-row {
    flex-direction: column;
  }

  .tooltip {
    position: absolute;
    /* background-color: #333; */
    background-color: transparent;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    /* bottom: 0px; */
    top: 0px;
    left: 0px;
    -webkit-transform: translate(-50%, -98%);
    transform: translate(-50%, -98%);
    display: inline-block;
    opacity: 0.8;
  }

  .analytics-single-property {
    max-width: 576px !important;
  }

  .properties-container {
    padding: 0px !important;
  }

  .global-chart-button-margin-bottom {
    margin-bottom: 20px !important;
  }

  .global-chart-text-padding {
    padding: 0px !important;
  }

  .tab-responsive-mr-15 {
    margin-right: 0px;
  }

  .chart-mr-left {
    margin-left: 0px;
  }

  .analytics-tabs-mobile {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .analytics-tabs-mobile > a {
    font-weight: bold !important;
  }

  .price-input-container {
    max-width: 40%;
  }
  .average-terms-container {
    margin-left: 30px;
  }
  .width-60 {
    width: 100%;
  }
  .my-data-global-data-mobile-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .my-data-global-data-mobile-btn > button {
    margin: 0 !important;
    width: 130px;
    background: #f8f8f8;
    border: 1px solid #ebebeb;
  }
  .my-data-global-data-mobile-btn > .gd-active {
    background-color: #109cf1 !important;
  }
  .summary-detail-container {
    flex-direction: column;
    margin-top: 16px;
    justify-content: initial;
    flex-wrap: inherit;
    align-items: initial;
  }
  .summary-detail-container > .Analytic_summaryDetails__2yEd_ {
    margin-bottom: 16px;
  }
  .summary-detail-container
    > .Analytic_summaryDetails__2yEd_
    > .Analytic_activeDetails__3sIRt,
  .summary-detail-container
    > .Analytic_summaryDetails__2yEd_
    > .Analytic_deActiveDetails__2uU8R {
    margin-bottom: 0;
    width: 50%;
    text-align: center;
    justify-content: center;
    height: 32px;
    font-size: 14px;
  }
  .mob-dropdown-style-width-100-perc,
  .mob-dropdown-style-width-100-perc > button,
  .mob-dropdown-style-width-100-perc > a,
  .mob-dropdown-style-width-100-perc > a > button {
    width: 100% !important;
  }
  .mob-dropdown-style {
    max-width: initial !important;
  }

  .mobile-tabs nav {
    display: -webkit-box !important;
    overflow-x: scroll !important;
    flex-wrap: nowrap !important;
  }

  .mobile-tabs nav::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 500px) {
  .map-draw-btn {
    top: 10%;
    left: 5%;
  }
}

@media (max-width: 480px) {
  .maps-filters-container {
    margin-top: -60px;
    margin-bottom: 60px;
  }
  .price-input-container {
    max-width: 95%;
  }
  .width-60 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .analytics-mobile-filters {
    margin: 5px 0;
    padding: 5px 0;
  }
  .mobile-responsive-map {
    margin-top: -50px;
  }
  .chartWrapper-scroll {
    width: 100% !important;
  }
}

@media (max-width: 428px) {
  .map-draw-btn {
    left: 7%;
  }
  .draw-map-container {
    height: 200px;
    padding: 0 10px;
    flex-direction: column;
    padding: 15px 15px 5px;
    text-align: center;
    left: 0 !important;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  }
}

@media (max-width: 320px) {
  .mobile-responsive-map {
    margin-top: -100px;
  }
  .price-input-container {
    max-width: 93%;
  }
  .chart-title-btn {
    font-size: 12px !important;
  }
  .maps-filters-container {
    margin-top: -100px;
    margin-bottom: 60px;
  }
}

.active-bg {
  background: #017c42;
  border-radius: 5px;
  color: #fff !important;
}

.search-icon-offers-table {
  height: auto;
  width: 20px;
  margin-left: 5px;
}

.search-bar-offers-table {
  text-decoration: underline !important;
  text-underline-offset: 5px !important;
}
