.cursor-pointer {
  cursor: pointer;
}

.dashboard-container {
  max-width: 1000px;
  margin: 30px;
}

.dashboard-main {
  padding: 30px 30px;
  background-color: #f6f7fa;
}

.sections-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #222222;
}

.header-subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-decoration-line: underline;

  transition: opacity ease 0.2s;
  opacity: 1;
}

.link:hover {
  opacity: 0.7;
  text-decoration-line: underline;
}

.header-second-row {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.activity-icon {
  font-size: 20px;
  color: #999999;

  margin-right: 10px;
}

.alert {
  padding: 9px 15px 9px 30px;
  border-radius: 12px;
  text-transform: initial;
}

.alert a,
.alert span {
  font-size: 16px;
}

.alert a {
  margin-left: 5px;
}

.alert .close-btn {
  font-size: 18px;
}

.alert-success {
  background: #28a745;
}

.alert-success span {
  color: #ffffff;
}

.alert-warning {
  background: transparent;
  border: 1px solid #d94b57;
}

.alert-warning span {
  color: #d94b57;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn {
  font-size: 16px;
  width: 100%;
  height: auto;
  margin: 3px auto;
  position: relative;
}

.tabs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.period-filter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.pagination > li.active > a {
  background-color: red;
  color: #fff;
}
.pagination > li > a,
.pagination > li > span {
  border-radius: 3px !important;
}

.pagination > li > a {
  box-shadow: none !important;
}

.period-filter-label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #999999;

  margin-right: 12px;
}

.result-per-page-label {
  margin-bottom: 0 !important;
}

.period-filter button {
  background-color: transparent;
  border: none;
}

.period-filter button span {
  border-bottom: 1px #222222 dashed;
  padding-bottom: 3px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-icon {
  margin-left: 10px;
  color: #cccccc;
}

.tab {
  border-bottom: 1px solid #999999;
  transition: border-color ease 0.1s;
}

.tab span {
  font-size: 22px;
  line-height: 22px;
  color: #999999;
  transition: color ease 0.1s;
}

.tab-active {
  border-bottom: 3px solid #222222;
}

.tab-active span {
  font-size: 22px;
  line-height: 22px;
  color: #222222;

  font-weight: bold;
  text-align: center;
}

.tab,
.tab-active {
  padding: 10px 25px;
}

.tab:hover > span {
  color: #666666;
}

.tab:hover {
  border-color: #666666;
}

.tab-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.properties-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px;
  border: 0px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.12);
}

/* DELETE IT */
.card + .card {
  margin-top: 0px !important;
}

.properties-card-icon {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #f6f7fa;
  margin-right: 45px;
  max-width: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties-card-info {
  width: 100%;
  text-align: center;
}

.properties-card-info h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 52px;
  line-height: 52px;
  color: #222222;
}

.properties-card-info span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #999999;
}

.list-heading {
  margin-top: 50px;
  margin-bottom: 30px;
}

.list-heading h5 {
  margin-bottom: 0px;
}

.list-heading .period-filter-label {
  margin-right: 10px;
}

.list-heading .period-filter .dropdown-toggle {
  border: 1px solid #cccccc;
  padding: 8px 6px;
  border-radius: 5px;
}

.list-heading .period-filter .dropdown-toggle span {
  border: 0px;
}

.list-heading .period-filter .dropdown-icon {
  margin-left: 30px;
}

.table-customized {
  border-collapse: separate;
  border-spacing: 0 0.7em;
}

.width-200 {
  width: 200px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.best-match-tag-table-view {
  background-color: #f8c007;
  color: #af6e00;
  margin-left: -17px;
  margin-top: -57px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
  position: absolute;
}

.my-listings-best-match-tag {
  background-color: #f8c007;
  color: #af6e00;
  margin-left: -41px;
  margin-top: -23px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
  position: absolute;
}

.first-td {
  border-left: 3px solid #f8c007 !important;
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
  min-width: 110px;
}

.td-in-btwn {
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
}

.last-td {
  border-right: 3px solid #f8c007 !important;
  border-top: 3px solid #f8c007 !important;
  border-bottom: 3px solid #f8c007 !important;
}

.properties-list-card {
  background-color: #ffffff;
  /* -webkit-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12); */
  padding: 15px 15px;
  margin-bottom: 30px;
}

.best-match-properties-list-card {
  background-color: #ffffff;
  /* -webkit-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12);
    box-shadow: 0px 0px 38px 0px rgba(0,0,0,0.12); */
  padding: 0 15px 15px;
  margin-bottom: 30px;
  border: 3px solid #f8c007;
}

.custom-best-match-left-border {
  border-left: 3px solid #f8c007;
  border-top: 3px solid #f8c007;
  border-bottom: 3px solid #f8c007;
  display: inherit;
}

.best-matched-normal-td-border {
  border-top: 3px solid #f8c007;
  border-bottom: 3px solid #f8c007;
}
/* border-left: 3px solid #f8c007;
border-top: 3px solid #f8c007;
border-bottom: 3px solid #f8c007;
display: block;
height: auto;
vertical-align: middle; */

.best-match-tag {
  background-color: #f8c007;
  color: #af6e00;
  margin: 0;
  margin-left: -16px;
  margin-top: -1px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 11px !important;
  padding: 2px 6px 2px 1px;
  font-weight: 600;
}

.best-match-tag i {
  color: #af6e00;
  margin-right: 10px;
}

.properties-list-card .sections-title {
  font-size: 20px;
  margin-bottom: 15px;
  max-height: 50px;
  height: 50px;
  overflow: hidden;
}

.properties-list-card .badge {
  margin-bottom: 15px;
}

.badge-success i,
.executed-badge i {
  margin-right: 5px;
}

.accepted-detail {
  margin-bottom: 15px;
}

.accepted-detail ul li {
  margin-bottom: 10px;
}

.accepted-detail p {
  font-size: 13px !important;
  color: #676767;
  margin: 0px 5px 0px 0px;
  min-width: 85px;
}

.accepted-detail a {
  font-size: 14px;
  color: #4d4d4d;
  width: 100%;
}

.accepted-detail .accepted-date {
  color: #222222;
  margin-right: 0;
}

.purchase-price {
  margin-bottom: 30px;
}

.purchase-price h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0px;
}

.purchase-price p {
  font-size: 26px;
  color: #ff214f;
  font-weight: 600;
  margin-bottom: 0px;
}

.house-detail {
  margin-bottom: 0px;
}

.house-detail ul li {
  margin-bottom: 10px;
}

.house-detail ul li:last-child {
  margin-bottom: 0px;
}

.house-detail p {
  width: 100%;
  font-size: 14px;
  color: #4d4d4d;
  margin-bottom: 0px;
}

.house-detail .btn-secondary {
  background-color: #28a745;
  font-size: 16px;
  border-color: #28a745;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

.house-detail .link {
  font-size: 16px;
  display: inherit;
  margin: 0 auto;
  text-align: center;
}

.view-more-button {
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: #000;
  text-decoration: underline;
  background: transparent !important;
}

.view-more-button:hover {
  color: #000;
}

.first-td-width {
  min-width: 45px;
  width: 45px;
  max-width: 45px;
}

.no-shadow {
  box-shadow: none !important;
}

.all-offers .sections-title {
  margin-bottom: 50px;
}

.all-offers .list-heading .period-filter .dropdown-toggle {
  margin-bottom: 10px;
}

.all-offers .period-filter-label {
  margin-bottom: 10px;
}

.slick-slider .slick-track {
  padding-left: 0px !important;
}

.slick-slide {
  padding-top: 30px;
}

.slick-slide .properties-list-card {
  margin-right: 20px;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  padding-left: 0px !important;
}

.slick-next,
.slick-prev {
  width: 0 !important;
  height: 0 !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 40px !important;
  opacity: 0.75 !important;
  /* border-radius: 50%;
  box-shadow: 1px 1px 10px #cccccc !important; */
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0 !important;
  display: none;
}

.slick-next {
  right: 20px !important;
}

.badge-review {
  background-color: #ffc107;
  color: #000;
}

.badge-seller-review {
  background: #fd8d14;
  color: #fff;
}

.see-all-offers {
  margin-bottom: 15px;
}

.see-all-offers .link {
  font-size: 14px;
  color: #4d4d4d;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  cursor: pointer;
}

.select-allbar {
  background-color: #e9ecef;
  padding: 10px 20px;
}

.select-allbar .link {
  font-size: 14px;
  color: #4d4d4d;
  border: 0px;
  background-color: transparent;
  padding: 0px;
  margin-right: 15px;
}

.all-offers .table thead th {
  background-color: #222222;
  color: #ffffff;
  border: 0;
  vertical-align: inherit;
}

.custom-checkbox {
  margin-right: 15px;
}

.all-offers .table .btn-theme {
  background-color: #28a745;
  border-color: #28a745;
  padding: 5px 25px 4px;
  font-size: 14px;
  margin-bottom: 15px;
}

.share-link {
  width: 100%;
  text-align: center;
}

.properties-card-img img {
  max-width: 100%;
  margin-bottom: 10px;
  width: 100%;
  max-height: 176px;
  height: 176px;
}

.dashboard-main .house-detail .link {
  text-decoration: none;
  color: #fff;
}

.pr-0 {
  padding-right: 0 !important;
}

.offer-address-data {
  min-width: 150px;
}

.row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-title {
  font-size: 30px;
  color: #222222;
  font-weight: 600;
}

.offers-table {
  width: 100%;
}

.modal-header {
  border-bottom: 0;
  padding: 20px 20px 0;
}

.modal-body {
  margin: 0 20px;
  padding: 0;
}

.modal-chart-btn {
  font-size: 14px !important;
  text-decoration: underline;
  color: #222222;
  text-transform: none !important;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  border: 0;
}

.modal-lg {
  max-width: 930px !important;
}

.modal-tr-header {
  background-color: black;
  color: white;
  border-top: 10px solid white;
}

.table-row {
  background-color: #f5f5f5;
  border-top: 10px solid white;
}

.address-td {
  width: 195px;
  padding: 20px 40px;
}

.modal-tr-header th {
  padding-left: 40px;
}

.table-row td {
  padding-left: 40px;
  color: #222222;
  font-weight: 500;
}

.go-to-offer-btn {
  color: #28a745 !important;
  background: transparent;
  padding: 7px 25px;
  border: 1px solid #28a745;
  border-radius: 5px;
  font-size: 16px;
  margin-right: 25px;
}

.go-to-offer-btn:hover {
  color: white !important;
  background: #28a745;
  border: 1px solid #28a745;
}

.mb-30 {
  margin-bottom: 30px;
}

.status-badge {
  padding: 3px 8px;
}

.review-badge {
  padding: 3px 8px;
  background: #ffc107;
  color: #222222;
}

.seller-review-badge {
  padding: 3px 8px;
  background: #fd8d14;
  color: #222222;
}

.review-badge i {
  margin-right: 5px;
}

.seller-review-badge i {
  margin-right: 5px;
}

.not-accepted-badge {
  padding: 3px 8px;
  background: #dc3545;
  color: white;
}

.executed-badge {
  padding: 3px 8px;
  background: #0a8c77;
  color: white;
}

.not-accepted-badge i {
  margin-right: 5px;
}

.offers-modal {
  overflow: hidden;
}

.offers-search-fields {
  padding: 11px 5px;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #e0e0e0;
  background: #fff;
  height: auto;
}

.all-offers-table-row {
  border-top: 8px solid #e5e5e5;
  background-color: white;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
}

.ml-6 {
  margin-left: 6px;
  margin-right: 0;
}
.pr-0 {
  padding-right: 0;
}

.delete-from-list {
  color: #222222 !important;
  text-decoration: underline !important;
  font-size: 16px !important;
  margin-bottom: 8px !important;
  margin-top: 6px !important;
  cursor: pointer;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #017c42;
  font-size: 13px;
  font-weight: 100;
}

.highchart-container {
  margin-bottom: 70px;
}

.highcharts-title {
  display: none;
}

.highcharts-credits {
  display: none;
}

.highcharts-axis text {
  text-align: right;
}

.unread-notification {
  color: #eb3e54 !important;
}

.read-notification {
  color: #9a9a9a !important;
}

.unread-dot {
  height: 7px;
  width: 7px;
  background-color: #eb3e54;
  border-radius: 7px;
  margin-top: 7px;
}

.dashboard-notification-text-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.allOffers-share-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: 5px;
}

.share-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.st-has-labels .st-btn.st-remove-label > span {
  display: inline-block !important;
}

.st-has-labels .st-btn.st-remove-label {
  min-width: 116px !important;
}

.st-btn {
  display: inline-block !important;
  margin-bottom: 8px;
}

.st-total {
  display: none !important;
}

.activities-container {
  border-left: 3px solid #b0b0b0;
  background: white;
  padding: 10px 10px;
  border-radius: 2px;
}

.activities-row {
  display: flex;
  flex-direction: row;
}

.margin-vert-6 {
  margin: 16px 0;
}

.activities-time {
  display: flex;
  flex-direction: row;
}

.back-sellers {
  margin-bottom: 0 !important;
}

.activity-time {
  color: #bbbbbb;
  font-size: 12px !important;
  margin: 0;
}

.activities-row p {
  font-size: 16px !important;
  margin: 0;
}

.activities-row i {
  margin-top: 3px;
}

.activities-dashboard {
  min-height: 90vh;
}

.badge-black {
  color: #fff;
  background-color: black;
}

.margin-top-20 {
  margin-top: 20px;
}

.delete-offer {
  background-color: transparent;
  border-radius: 3px;
  color: black;
  font-weight: 500;
  margin-bottom: 5px;
  border: 0;

  cursor: pointer;
  font-size: 18px;
  text-decoration: underline;
}

.delete-title {
  font-size: 24px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 30px;
}

.confirm-delete-btn {
  background-color: #28a745;
  border: 0;
  padding: 5px 55px 4px;
  font-size: 14px;
  margin-bottom: 20px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}

.cancel-btn {
  background-color: #dc3545;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 20px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}
.cancel-agent-btn {
  background-color: #dc3545;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  padding: 5px 55px 4px;
  cursor: pointer;
  margin-right: 15px;
}
.add-agent-btn {
  background-color: #28a745;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  border: 0;
  padding: 5px 26px 4px;
  cursor: pointer;
  margin-right: 15px;
}

.delete-btn {
  margin: 5px 0 15px;
}

.delete-btn-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.webkit-appearance-auto {
  -webkit-appearance: button-bevel !important;
}

/* Custom CSS */
.react-joyride__tooltip button span {
  color: #fff !important;
  font-size: 12px;
  font-weight: 1000;
  /* padding: 0px; */
}

.react-joyride__tooltip {
  padding-top: 30px !important;
}

.react-joyride__tooltip p {
  font-size: 12px;
}

.react-joyride__tooltip h5 {
  font-size: 22px;
  font-weight: 600;
  color: #222222;
}

.react-joyride__tooltip button {
  height: 20px;
  padding: 0px !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
  border-radius: 3px;
  padding-top: 8px !important;
  padding-bottom: 18px !important;
  font-size: 12px !important;
  font-weight: 1000;
}

.react-joyride__tooltip button[title="Close"] {
  top: 10px !important;
}
.dashboard-modal-text {
  font-size: 12px !important;
  color: gray;
}
.dashboard-modal-text-left {
  font-size: 12px !important;
  color: gray;
  text-align: left !important;
}
.dashboard-modal-text-red {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #dc3545 !important;
  text-align: left !important;
}

.st-has-labels .st-btn.st-remove-label {
  min-width: 95px !important;
}

@media (max-width: 992px) {
  .modal-lg {
    max-width: 800px !important;
  }
  .table-row td {
    padding-left: 10px;
  }
  .modal-body {
    margin: 0;
    padding: 10px;
  }
  .go-to-offer-btn {
    margin-right: 10px;
  }
  .offers-table {
    min-width: 690px;
    overflow-x: scroll;
    width: 100%;
  }
  .modal-tr-header th {
    padding-left: 10px;
  }
  .my-listings-best-match-tag {
    margin-left: -11px;
  }
}

@media (max-width: 415px) {
  .modal-title {
    font-size: 20px;
  }

  .period-filter-label {
    display: block;
    text-align: center;
  }

  .sellers-status-filter {
    text-indent: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  .tab-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .slick-slide .properties-list-card {
    margin-right: 0;
  }

  .accepted-detail p {
    font-size: 13px !important;
    min-width: 85px;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-main {
    padding: 0px 0px;
    background-color: #f6f7fa;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .tab-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 1224px) {
  .tab-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
